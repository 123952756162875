@import "../../../sass/generalUtility";
@import "../../../sass/colorPalette";
@import "../../../sass/fontSizeUtility";

.notesHistory {
  border: 1px solid #333;
  border-radius: 4px;
  > p {
    padding: 0.5rem;
    @include font_size(b04);
    text-transform: capitalize;
    font-weight: 500;
    border-bottom: 1px solid #333;
  }
  .notesContent {
    max-height: 8rem;
    overflow-y: scroll;
    .historyDisplay {
      @include font_size(b04);
      padding: 0.5rem;
      display: flex;
      gap: 2rem;
      min-height: 4rem;
    }
    .emptyDisplay {
      min-height: 8rem;
      @include font_size(h01);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
