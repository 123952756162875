@import "../../../../sass/fontSizeUtility";
@import "../../../../sass/buttonModules";
@import "../../../../sass/colorPalette";

.addDetailsContainer {
  width: 90%;
  margin: 0 auto;
  padding: 1rem;
  input[type="date"]:in-range::-webkit-datetime-edit-year-field,
  input[type="date"]:in-range::-webkit-datetime-edit-month-field,
  input[type="date"]:in-range::-webkit-datetime-edit-day-field,
  input[type="date"]:in-range::-webkit-datetime-edit-text {
    color: transparent;
  }
  height: 60vh;

  .head {
    position: relative;
    width: 70%;
    margin: 0 auto;
    display: flex;
    gap: 8rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      top: 50%;
      left: 0;
      background-color: $primary_color-1;
      position: absolute;
      z-index: -1;
    }
    .element {
      background-color: #fff;
      color: $primary_color-1;
      font-size: large;
      font-weight: 500;
      border: 1px solid $primary_color-1;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.active {
        background-color: $primary_color-1;
        color: #fff;
      }
    }
  }
  .title {
    width: 100%;
    background-color: #eee;
    padding: 0.5rem;
    text-align: center;
    font-weight: 500;
    @include font_size(b04);
  }
  .details-sec {
    display: flex;
    gap: 3rem;
    position: relative;

    .detail-content {
      width: 50%;
      height: 40vh;
      // background-color: #f00;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;
      > select {
        .default {
          display: none;
        }
      }
      .details-element {
        // width: 70%;
        @include font_size(b04);
      }
      .descri-color {
        > textarea {
          background-color: #eee;
        }
      }
    }
    > button {
      @include login-button-styles();
      width: 10rem;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .showGoalsContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    // border:1px solid #000;
    // padding: .5rem;
    height: 30vh;
    overflow-y: scroll;
    // border-radius: 9px;
    background-color: #eee;
    border-collapse: collapse;
    .goalsDisplay {
      display: flex;
      justify-content: space-between;
      padding: 2rem 1rem;
      border-bottom: 1px solid #000;

      .goalsText {
        @include font_size(b03);
        color: #222;
        display: flex;
        gap: 0.5rem;
        .hash {
          color: $primary_color-1;
          font-weight: 600;
        }
      }
      .goalsBtnCont {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        .goalsBtnWrap {
          .goalsBtn {
            cursor: pointer;
            color: $primary_color-1;
            &:hover {
              color: $primary_color-dark-1;
            }
          }
        }
      }
    }
  }

  .addDetailsToggle {
    display: flex;
    justify-content: end;
    padding: 1rem 0;
    gap: 1rem;
    .btnGoalsToggle {
      // border-radius:4px;
      width: 10rem;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      cursor: pointer;
      border: none;
      color: #222;
      font-weight: 600;
    }
  }
}
