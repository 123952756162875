@import "../../../sass/generalUtility";
@import "../../../sass/fontSizeUtility";
.conference-section {
  width: 100%;
  .peers-container {
    padding-top: 10px;
    position: relative;
    width: 100%;
    gap: 1rem;
    height: auto;
    aspect-ratio: 3.15;
    margin-bottom: 12rem;
    .peer-container {
      position: absolute;
      transition: all 350ms ease-in-out;
      &.big {
        top: 10px;
        right: 25%;
        width: 50%;
      }
      &.small {
        width: 20%;
        top: 50%;
        right: 20%;
        z-index: 10;
      }
    }
  }
}

.peer-video {
  border: #fff 1px solid;
  height: auto;
  width: 100%;
  aspect-ratio: 1.6;
  border-radius: 1rem;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: #acacac;
  transition: inherit;

  .personName {
    width: 10rem;
    height: auto;
    aspect-ratio: 1;
    background-color: #7a5dda;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 500;
    color: #fff;
    transition: inherit;
  }

  &.local {
    transform: scaleX(-1);
  }
}

.peer-container.small {
  .peer-video {
    .personName {
      width: 8rem;
      font-size: 40px;
    }
  }
}

.peer-name {
  font-size: 14px;
  text-align: center;
  > .microPhone {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    background-color: #cccccc;
    border-radius: 50%;
    outline: none;
    border: none;
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 2rem;
      height: 2rem;
      path {
        fill: #222222;
      }
    }
    // background-color: #f00;
    transition: all 0ms ease-in-out;
    &::after {
      position: absolute;
      content: "";
      width: 0;
      border-top: 1px solid #f00;
      height: 2px;
      background-color: #fff;
      content: "";
      top: 28%;
      left: 28%;
      transform-origin: 0;
      transform: translate(0, -1px) rotateZ(45deg);
      transition: inherit;
    }
    &.disabled {
      background-color: #f00;
      transition: all 350ms ease-in-out;
      &::after {
        width: 60%;
        transition: inherit;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
    //  {
    // }
  }
}
