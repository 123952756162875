@import "../../sass/generalUtility";
@import "../../sass/buttonModules";
@import "../../sass/widgetUtility";
@import "../../sass/fontSizeUtility";

@import "../../sass/sizes";
@import "../../sass/colorPalette";
.tabWidgetContainer {
  margin-top: 1rem;
  .TitleElement {
    margin-top: 1rem;
    @include font_size(b01);
    font-weight: 600;
    color: #222;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    .Updatebtn {
      @include login-button-styles;
      width: 10rem;
      height: 4rem;
      @include font_size(b03);
      text-transform: capitalize;
      margin-right: 5rem;
    }
  }
  .container {
    width: 60%;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    .grid {
      width: 100%;
      margin-left: 20rem;
      .lineElement {
        width: 100%;
        height: 4rem;
        margin-bottom: 1rem;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        .label {
          @include font_size(b03);
          font-weight: 500;
          color: #222;
          width: 40%;
        }
        .field {
          width: 60%;
          @include font_size(b04);
          padding: 0.5rem;
        }
        .field2 {
          width: 60%;
          @include font_size(b04);
        }
      }
    }
  }
}
