@import "../sass/generalUtility";
@import "../sass/buttonModules";
@import "../sass/widgetUtility";
@import "../sass/fontSizeUtility";
@import "../sass/utility.scss";

.CallingTimer {
  width: 60%;
  margin: 0 auto;
  display: grid;
  height: 30vh;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #000;
  border-radius: 8px;
  margin-top: 3rem;
  overflow: scroll;

  .left {
    border-right: 1px solid #000;
    .first {
      padding: 2rem;
      border-bottom: 1px solid #000;
    }
    .second {
      padding: 2rem;
    }
    .top {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .head {
        @include font_size(b02);
        font-weight: 400;
      }
      .time {
        @include font_size(h01);
        font-weight: 600;
      }
    }
  }
  .rigth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    .headTitle {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-bottom: 4rem;
      @include font_size(h01);
      p {
        @include font_size(h01);
      }
    }
    .stopwatchContainer {
      //   flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      .timerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .serviceData {
    overflow: scroll;
    .gridBody {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      border: 1px solid #888;
      border-radius: 4px;
      height: min-content;
      // padding-bottom: 10rem;
      overflow: scroll;
    }

    .gridHeader {
      display: contents;
    }

    .gridHead {
      position: sticky;
      top: 0;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #888;
      border-bottom: 1px solid #888;
      @include font_size(b05);
      padding: 0.5rem;
      color: #222;
      font-weight: 500;
      z-index: 1;
    }

    .gridContent {
      display: contents;
    }

    .gridBox {
      display: flex;
      justify-content: center;
      @include font_size(b05);
      border-right: 1px solid #888;
      border-bottom: 1px solid #888;
      align-items: center;
      font-weight: 400;
      padding: 1rem;
      color: #222;
    }
    .totalcpmd {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      @include font_size(b04);
      font-weight: 600;
      .total {
        padding: 0.5rem;
        text-align: end;
        border-right: 1px solid #000;
      }
      .totalTime {
        padding: 0.5rem;
        text-align: start;
        border-right: 1px solid #000;
      }
    }
  }
}
