@import "../../sass/_generalUtility.scss";
@import "../../sass/buttonModules";

.clinician {
  @include layout-padding-property();
  padding-bottom: 1rem;
  .created {
    @include font_size(b03);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 1rem;
    align-items: start;
    // margin-bottom: 2rem;
  }
  .clinicianContainer {
    .pageTitle {
      @include page-title-styles();
    }
    .clinicianContainer1 {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(3, calc(3rem + 2.5vw));
      grid-column-gap: 1rem;
    }
    .clinicianContainer2 {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
      grid-template-rows: 1fr calc(1.5rem + 1vw) calc(1.5rem + 1vw);
    }
    .btnWrap {
      display: flex;
      gap: 1rem;
      .buttonContainer {
        width: calc(10rem + 6vw);
        margin-top: calc(1.2rem + 1vw);
        button {
          @include login-button-styles();
        }
      }
    }
  }
}
