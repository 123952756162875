@import "./../sass/fontSizeUtility";
@import "./../sass/generalUtility";
.navComponent {
  width: 100%;
  background-color: #ddd;
  .navContent {
    @include layout-padding-property();
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    .link {
      display: inline-block;
      text-transform: capitalize;
      cursor: pointer;
      @include font_size("b04");
      color: #888;
      //   letter-spacing: 0.5px;
      &:hover {
        color: #888;
        // font-weight: 500;
      }
    }
  }
}
