@import "sizes";
@mixin font_size($size) {
  line-height: 120%;
  @if $size == 1 {
    font-size: 2rem;
  } @else if ($size == 2) {
    font-size: 1.8rem;
  } @else if ($size == 3) {
    font-size: 1.6rem;
  } @else if ($size == 4) {
    font-size: 1.4rem;
  } @else if ($size == 5) {
    font-size: 1.2rem;
  } @else if ($size == 6) {
    font-size: 1rem;
  } @else if ($size == 7) {
    font-size: 0.8rem;
  } @else if ($size == 8) {
    font-size: 0.6rem;
  } @else if ($size == "h06") {
    font-size: calc(16.23px + 1.621 * 1vw);
  } @else if ($size == "h05") {
    font-size: calc(14.41px + 1.442 * 1vw);
    @include size500 {
      font-size: 3rem;
    }
    @include size400 {
      font-size: 2.8rem;
    }
  } @else if ($size == "h04") {
    font-size: calc(12.82px + 1.281 * 1vw);
    @include size500 {
      font-size: 2.8rem;
    }
    @include size400 {
      font-size: 2.6rem;
    }
  } @else if ($size == "h03") {
    font-size: calc(11.39px + 1.139 * 1vw);
    @include size500 {
      font-size: 2.6rem;
    }
    @include size400 {
      font-size: 2.4rem;
    }
  } @else if ($size == "h02") {
    font-size: calc(10.12px + 1.013 * 1vw);
    @include size500 {
      font-size: 2.4rem;
    }
    @include size400 {
      font-size: 2.2rem;
    }
  } @else if ($size == "h01") {
    font-size: calc(9px + 0.9 * 1vw);
    @include size500 {
      font-size: 2.2rem;
    }
    @include size400 {
      font-size: 2rem;
    }
  } @else if ($size == "b01") {
    font-size: calc(8px + 0.8 * 1vw);
    @include size500 {
      font-size: 2rem;
    }
    @include size400 {
      font-size: 1.8rem;
    }
  } @else if ($size == "b02") {
    font-size: calc(7.11px + 0.711 * 1vw);
    @include size500 {
      font-size: 1.8rem;
    }
    @include size400 {
      font-size: 1.6rem;
    }
  } @else if ($size == "b03") {
    font-size: calc(6.32px + 0.632 * 1vw);
    @include size500 {
      font-size: 1.6rem;
    }
    @include size400 {
      font-size: 1.4rem;
    }
  } @else if ($size == "b04") {
    font-size: calc(5.62px + 0.562 * 1vw);
    @include size500 {
      font-size: 1.5rem;
    }
    @include size400 {
      font-size: 1.3rem;
    }
  } @else if ($size == "b05") {
    font-size: calc(5px + 0.499 * 1vw);
    @include size500 {
      font-size: 1.2rem;
    }
    @include size400 {
      font-size: 1rem;
    }
  }
}
