@import "../../sass/generalUtility";
@import "../../sass/buttonModules";
@import "../../sass/widgetUtility";
@import "../../sass/fontSizeUtility";

@import "../../sass/sizes";
@import "../../sass/colorPalette";

.ViewPageContainer {
  @include layout-padding-property();
  margin-top: calc($topBarHeight + 1vw);

  .Title {
    // width: 100%;
    @include page-title-styles;
  }
  .dropDownControl {
    // height: 10rem;
    // overflow-y: scroll;
  }
  select {
    .default {
      display: none;
    }
  }
  .tableContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 8px;
    border: 1px solid #888;
    background: #eee;
    row-gap: 1rem;
    padding: 0 1rem 1rem 1rem;
    .tableelement {
      display: flex;
      flex-direction: column;
      //   justify-content: center;
      padding-top: 2.5rem;
      gap: 1rem;
      .detailelements {
        @include font_size(b04);
        color: #222;
        font-weight: 500;
      }
      .primaryDetails {
        display: flex;
        .contact {
          width: 30%;
          //   height: 100%;
        }
        .details {
          width: 100%;
          display: flex;
          color: $primary_color-1;
          font-weight: 600;
          align-items: center;
          .name {
            @include font_size(h01);
            margin-bottom: 0.5rem;
          }
          .dob {
            margin-bottom: 0.5rem;
            @include font_size(b05);
          }
          .cordinator {
            margin-bottom: 0.5rem;
            @include font_size(b03);
          }
        }
      }
    }
    .tableelement.detailsHead {
      padding-top: 0;
    }
    .btns {
      grid-column: 3/4;
      display: flex;
      justify-content: space-between;
      .viewButton {
        border: 1px solid #7a5dda;
        border-radius: 4px;
        display: flex;
        padding: 5px 20px;
        align-items: center;
        gap: 4px;
        @include font_size(b05);

        color: #7a5dda;
        font-weight: 600;
      }
    }
  }
  .tabWidgetContainer {
    margin-top: 1rem;
    .TitleElement {
      margin-top: 1rem;
      @include font_size(b01);
      font-weight: 600;
      color: #222;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      .Updatebtn {
        @include login-button-styles;
        width: 10rem;
        height: 4rem;
        @include font_size(b03);
        text-transform: capitalize;
        margin-right: 5rem;
      }
    }
    .container {
      width: 60%;
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr 1fr;
      .grid {
        width: 100%;
        margin-left: 20rem;
        .lineElement {
          width: 100%;
          height: 4rem;
          margin-bottom: 1rem;
          display: flex;
          gap: 1rem;
          justify-content: space-between;
          align-items: center;
          .label {
            @include font_size(b03);
            font-weight: 500;
            color: #222;
            width: 40%;
          }
          .field {
            width: 60%;
            @include font_size(b04);
            padding: 0.5rem;
          }
          .field2 {
            width: 60%;
            @include font_size(b04);
          }
        }
      }
    }
  }
}
