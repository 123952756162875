@import "../sass/fontSizeUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";

.dropContainer {
  border: 1.5px solid #888888;
  overflow: hidden;
}
.Container {
  margin: 1rem;
  width: 98%;
  border-radius: 4px;
  background-color: #fff;
  .title {
    @include font_size(b03);
    background-color: #eee;
    font-weight: 600;
    display: grid;
    grid-template-columns: 1fr min-content;
    justify-content: space-between;
    align-items: center;
    border: none;
    align-items: center;
    padding: 5px 5px 5px 0;
    // padding: .5rem;
    // border-bottom:1.5px solid #888888 ;

    .title_icon {
      display: flex;
      align-items: center;
      > p {
        @include font_size(b03);
      }
    }
    .createSheduleButton {
      display: flex;
      justify-content: center;
      gap: 1rem;
      align-items: center;
      padding: 4px 8px;
      border: none;
      background-color: $primary_color-1;
      color: #fff;
      @include font_size(b03);
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: $primary_color-dark-1;
      }
      > p {
        white-space: nowrap;
      }
    }

    .icon {
      // margin-right: 1rem;
      padding-top: rem;
    }
  }
  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-right: 1rem;
    .boxBtn {
      display: flex;
      padding: 5px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border: none;
      outline: none;
      border-radius: 2px;
      background-color: $primary_color-1;
      white-space: nowrap;
    }
  }
  .body {
    padding-bottom: 1rem;
  }
  .dropContent {
    .content {
      background-color: #fff;
      padding: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      .gridComponent {
        .dropElements {
          margin-bottom: 1.5rem;
          display: grid;

          grid-template-columns: 1fr 2fr;
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
          .checkboxCont {
            display: flex;
            // justify-content: center;
            gap: 1rem;
            margin-bottom: 0.5rem;
            align-items: center;
          }
          .dropElementsLabel {
            @include font_size(b04);
          }
          > input {
            height: 2.5rem;
          }
        }
      }
      .Note {
        grid-column: 1 / 3;
        @include font_size(b03);
        font-weight: 500;
        color: #eb7134;
      }
    }
  }
}
