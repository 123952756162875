@import "../../sass/_generalUtility.scss";
@import "../../sass/buttonModules";
@import "../../sass/widgetUtility";
@import "../../sass/fontSizeUtility";

@import "../../sass/sizes";
@import "../../sass/colorPalette";
.facility {
  margin-bottom: 1rem;
  @include layout-padding-property();
  padding-bottom: 1rem;
  .created {
    @include font_size(b03);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: start;
  }
  .facilityContainer {
    .tabFacility {
      display: flex;
      justify-content: end;
      align-items: center;
      padding-bottom: 1rem;
      padding-right: 1rem;
    }
    .pageTitle {
      @include page-title-styles();
    }
    .facilityBasicInfoContainer1 {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, calc(3rem + 2.5vw));
      grid-column-gap: 1rem;
    }
    .workingDaysContainer {
      row-gap: 0;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;

      .workingTitle {
        @include font_size(b03);
        color: #1e1f5f;
        margin-bottom: -6px;
      }
    }
    .workingTimeContainer {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
      .workingTitle {
        @include font_size(b03);
        color: #1e1f5f;
        margin-bottom: -6px;
      }
    }
    .facilityBasicInfoContainer2 {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(2, calc(3rem + 2.5vw));
      grid-column-gap: 1rem;
    }
    .colSpan6 .rowSpan2 .created {
      justify-content: center;
      align-items: center;
    }
    .btnWrap {
      display: flex;
      gap: 1rem;
      .buttonContainer {
        width: calc(10rem + 6vw);
        margin-top: calc(1.2rem + 1vw);
        button {
          @include login-button-styles();
        }
      }
    }
    .accessLevelComponenets {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .accessLevelContainers {
        width: 45%;
      }
    }
  }
}
