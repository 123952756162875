@import "../../../sass/fontSizeUtility";

.setThreshold {
  width: 100%;
  .mainContainer {
    height: 60vh;
    overflow-y: scroll;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    .Cont {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;

      .threDisplay {
        .Element {
          @include font_size(b04);
          text-align: center;
        }
        .bold {
          font-weight: 600;
        }
        .strigthDisplay {
          display: flex;
          padding: 1rem;
          > .Element {
            padding-right: 2.5rem;
          }
        }
        .bmi {
          margin: 1rem;
          padding: 1rem;
          border: 1px solid #000;
          border-radius: 4px;
          //   display: grid;
          //   grid-template-columns: repeat(2, 1fr);

          .bmiTitle {
            @include font_size(b03);
            font-weight: 600;
            text-align: center;
            padding: 0 0 1.5rem 0;
          }
          .displayGrid {
            margin-bottom: 1rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            > .Element {
              grid-column-end: span 1;
            }
          }
        }
        .pressure {
          margin: 1rem;
          padding: 1rem;
          border: 1px solid #000;
          border-radius: 4px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .grid2 {
            margin-bottom: 1rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 1rem;
            .gridHead {
              grid-column-end: span 2;
              @include font_size(b03);
              font-weight: 600;
              text-align: center;
              padding: 0 0 1.5rem 0;
              text-align: center;
            }
          }
        }
      }
      .addDetails {
        .addBox {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          padding: 1rem;
          margin: 1rem;
          gap: 1rem;
          border: 1px solid #ccc;
          .elem {
            grid-column-end: span 1;
            display: flex;
            gap: 1rem;
            flex-direction: column;
            > input {
              padding: 0.5rem;
            }
            .dropElementsLabel {
              @include font_size(b04);
            }
          }
        }
      }
      .bloodPressure {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .addBoxPressure {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 1rem;
          margin: 1rem;
          gap: 1rem;
          border: 1px solid #ccc;
          .head {
            grid-column-end: span 2;
            @include font_size(b03);
            font-weight: 600;
            text-align: center;
            padding: 0 0 1.5rem 0;
            text-align: center;
          }
          .elem {
            grid-column-end: span 1;
            display: flex;
            gap: 1rem;
            flex-direction: column;
            > input {
              padding: 0.5rem;
            }
            .dropElementsLabel {
              @include font_size(b04);
            }
            .hide {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
