@import "../sass/generalUtility";
@import "../sass/buttonModules";
@import "../sass/widgetUtility";
@import "../sass/fontSizeUtility";
@import "../sass/sizes";
@import "../sass/colorPalette";

.callStatusPopUp {
  .popUp {
    .popTitle {
      color: #222;
      font-weight: 500;
      @include font_size(b02);
      text-align: center;
      margin-bottom: 4rem;
    }
    .popElementHead {
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      .popLabelBig {
        color: #222;
        font-weight: 400;
        @include font_size(b03);
      }
      .popElemenSelectt {
      }
      .popElemenDate {
        display: grid;
        grid-template-columns: 2fr 1fr;
        > span {
          display: flex;
          gap: 1rem;
          > input {
            height: 2.5rem;
          }
        }
      }
      .popElement {
        input {
          padding: 0.5rem;
          height: 2.5rem;
        }
      }
    }
    .warning {
      color: #f00;
      text-align: center;
      margin: 3rem 0;
      @include font_size(b03);
    }

    .popBtn {
      width: 100%;
      text-align: end;
      margin-top: 1rem;
      > button {
        // width: 10rem;
        padding: 8px 16px;
        border: none;
        background-color: $primary_color-1;
        border: 1px solid $primary_color-1;
        color: #fff;
        @include font_size(b04);
        border-radius: 3px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-right: 1rem;
      }
      :nth-child(2) {
        color: $primary_color-1;
        background-color: #fff;
      }
    }
  }
}
