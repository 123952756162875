@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/buttonModules";
.ListEligiblePatients {
  @include layout-padding-property();
  .title {
    @include page-title-styles();
  }
  .body {
    margin-top: 2rem;
    .dataFile {
      @include font_size(b04);
      margin-bottom: 1rem;
    }
    .buttonsContainer {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 1rem;
      > button {
        @include font_size(b04);
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        &:nth-child(1) {
          background-color: #f00;
          &:hover {
            background-color: #ff4d4d;
          }
        }
        &:nth-child(2) {
          background-color: #957de1;
          &:hover {
            background-color: #a28ee5;
          }
        }
        &:nth-child(3) {
          background-color: $primary_color-1;
          &:hover {
            background-color: $primary_color-light-1;
          }
        }
        &:nth-child(4) {
          background-color: #2aaa8a;
          &:hover {
            background-color: #6ac4ad;
          }
        }
      }
    }
    .eligibleTable {
      display: grid;
      grid-template-columns: repeat(20, 1fr);
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      .head {
        @include font_size(b04);
        display: flex;
        align-items: center;
        padding: 1rem;
        justify-content: center;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-weight: 400;
      }

      .cntnt {
        @include font_size(b05);
        display: flex;
        align-items: center;
        padding: 1rem;
        justify-content: center;
        border-right: 1px solid #ccc;
        font-weight: 400;
        border-bottom: 1px solid #ccc;
        .action {
          display: flex;
          gap: 1rem;
          > button {
            border: none;
            background: transparent;
            border-radius: 50%;
            padding: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: #ccc;
            }
          }
        }
        .patient {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          > p {
            > span {
              font-weight: 600;
            }
          }
        }
      }
      .pgmcontainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        padding: 0;

        > span {
          height: 100%;
          padding: 1rem;
          // width: 100%;
          // overflow: scroll;
        }
        .disabled {
          background-color: #ddd;
        }
        .pgm {
          border-right: 1px solid #ccc;
        }
        .inp {
          > button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .btnWrap {
          padding: 0 5px;
          margin-bottom: 5px;
          > button {
            padding: 5px 0;

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
