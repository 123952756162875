@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
.calMainCont {
  .createButton {
    width: 100%;
    display: flex;
    justify-content: end;
    .createSheduleButton {
      display: flex;
      justify-content: center;
      gap: 1rem;
      align-items: center;
      padding: 4px 8px;
      border: none;
      background-color: $primary_color-1;
      color: #fff;
      @include font_size(b03);
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: $primary_color-dark-1;
      }
      > p {
        white-space: nowrap;
      }
    }
  }
  .advanceSearchBox {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
    .box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      //   align-items: baseline;
      .head {
        @include font_size(b03);
      }
      > input {
        width: 100%;
        height: 2.5rem;
        padding: 0.7rem;
        border: 1px solid #000;
      }
    }
  }

  .calenderHeader {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .calenderIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 3rem;
      }
    }
    .monthTitle {
      @include font_size(h01);
    }
    .navCalender {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #888;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      .ele {
        padding: 0.5rem;
        @include font_size(b04);
        border-right: 1px solid #888;
        background-color: #eee;
      }
      .active {
        background-color: #ddd;
      }
    }
  }
  .calendarBody {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid #888;
    margin-top: 2rem;
    .day {
      @include font_size(b03);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      grid-column: span 1;
      border-bottom: 1px solid #888;
      border-right: 1px solid #888;
      padding: 1rem;
    }
    .dayComponent {
      display: flex;
      flex-direction: column;
      //   align-items: center;
      gap: 1rem;
      justify-content: start;
      border-bottom: 1px solid #888;
      border-right: 1px solid #888;
      grid-column: span 1;
      height: 16rem;
      > p {
        @include font_size(b04);
        text-decoration: underline;
      }
      .eventComp {
        overflow-y: scroll;
      }
      .eventTitle {
        @include font_size(b04);
        padding: 0 5px;
        margin: 5px 5px;
        border-radius: 4px;
        background-color: $primary_color-1;
        color: #fff;
      }
    }

    .isMonth {
      color: #ccc;
    }
    .present {
      background-color: #fcf8e3;
    }
  }
  .weekBody {
    border: 1px solid #888;
    margin-top: 2rem;
  }
}
