@import "../sass/fontSizeUtility";

.remarksViewer {
  .buttonremarks {
    @include font_size(b04);
    border: none;
    background: none;
    cursor: pointer;
  }
  .overlayComponent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    .remarksSection {
      width: 60vw;
      position: relative;
      top: 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      margin: 0 auto;
      margin-bottom: 2rem;
      border-radius: 8px;
      padding: 16px;
      background: #fff;
      z-index: 9999;
      .remarkstitle {
        @include font_size(h02);
        text-align: center;
        margin-bottom: 3rem;
      }
      .remarksTable {
        position: relative;
        .remarksGrid {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          border: 1px solid #888;
          background-color: #fff;

          &.header {
            position: sticky;
            top: 0;
            z-index: 10;
            font-weight: 600;
            background-color: #fff;
          }

          .Element {
            -webkit-box-orient: vertical;
            display: flex;
            align-items: start;
            justify-content: start;
            border-right: 1px solid #888;
            @include font_size(b03);
            padding: 0.5rem;
            overflow-wrap: break-word;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
        }

        .remarksGrid.contentBody {
          max-height: 30vh;
          overflow-y: scroll;
          border-top: none;
        }
      }
    }
  }
}
