@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
.weekTime {
  height: 4rem;
  position: relative;
  @include font_size(b06);
  .upper {
    position: absolute;
    right: 4px;
    top: 0;
    transform: translateY(-50%);
  }
  .lower {
    position: absolute;
    right: 4px;
    bottom: 0;
    transform: translateY(50%);
  }
}
.weekElem {
  // background-color: #f00;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-left: 1px solid #888;
  // border: 1px solid #888;
  .ele {
    overflow-y: scroll;
    height: 4rem;
    grid-column: span 1;
    border-bottom: 1px solid #888;
    border-right: 1px solid #888;
  }
  .eventComp {
  }
  .eventTitle {
    @include font_size(b04);
    padding: 0 5px;
    margin: 5px 5px;
    border-radius: 4px;
    background-color: $primary_color-1;
    color: #fff;
  }
}
.weekelem2 {
  display: grid;
  grid-template-columns: 1fr;
}
