@import "../sass/fontSizeUtility";
@import "../sass/buttonModules";
@import "../sass/colorPalette";
.login {
  position: relative;

  width: 100%;
  height: 100vh;
  .loginDesignContainer1 {
    content: "";
    top: 0;
    right: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    overflow: hidden;

    img.bg {
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0% 100%;
    }
    img.loginDoll {
      z-index: 3;
      position: absolute;
      bottom: 0%;
      right: 0%;
      width: auto;
      height: 70%;
      object-fit: cover;
      object-position: 0% 100%;
    }
  }
  .loginContainer1 {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    .loginContainer1_1 {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .loginLeftContainer1 {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;

      .loginLeftContainer1_1 {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(25vw + 10rem);
        max-width: 30vw;
        height: calc(32rem + 4vw);
        background-color: #fff;

        > .loginImg {
          content: "";
          position: absolute;
          // top: 0;
          bottom: 100%;
          left: 50%;
          transform: translate(-50%, -4rem);
        }
        > .title {
          @include font_size("h03");
          font-weight: 600;
          margin-bottom: 2rem;
        }
        > .desc {
          @include font_size(b04);
          margin-bottom: 2rem;
        }
        form {
          width: 100%;
          > .forgotUserName,
          > .forgotPassword {
            width: 100%;
            text-align: right;
            @include font_size(b04);
            cursor: pointer;
            color: #7a5dda;
            margin-top: 1rem;
          }
          > .buttonContainer {
            width: 100%;
            margin-top: calc(1.2rem + 1vw);
            button {
              @include login-button-styles();
            }
          }
        }
        > .issueNavigation {
          margin-top: 1rem;
          cursor: pointer;
          > span {
            color: $primary_color-1;
            @include font_size(b04);
            font-weight: 500;
            letter-spacing: 0.02em;
          }
        }
        > .errorLogin {
          width: 100%;
          background-color: rgb(255, 219, 219);
          color: #f00;
          text-align: center;
          @include font_size(b04);
          margin: 1rem;
          line-height: 150%;
          &.hidden {
            opacity: 0;
          }
        }
      }
    }
    .loginRightContainer1 {
      position: relative;

      padding-top: 5%;
      padding-left: 5%;
      z-index: 10;
      .title {
        @include font_size("h04");
        font-weight: 600;
        color: #fff;
      }
      .descContainer {
        position: relative;
        width: fit-content;
        padding-top: 30px;
        padding-bottom: 13px;
        color: #fff;
        @include font_size("h01");
        font-weight: 600;

        .desc {
          padding-bottom: 0.3rem;
          span {
            color: #fad787;
            @include font_size("h02");
            font-weight: 700;
            letter-spacing: 0.02em;
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #fad787;
          width: 75%;
          height: 5px;
          border-radius: 1rem;
        }
      }
    }
  }
}
