@import "./_colorPalette.scss";
@import "./fontSizeUtility";
.tabElement2 {
  background-color: $primary_color-light-4;
  border-radius: 0.5rem;

  padding: 0.7vw 1.5vw;
  cursor: pointer;
  @include font_size(b03);
  transform: all 350ms ease-in-out;

  &.active {
    background-color: $primary_color-light-1;
    color: #fff;
  }
}
