@import "../../sass/fontSizeUtility";

.viewGridContainer {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 4rem;
  .viewSide {
    .viewElement {
      display: flex;
      gap: 2rem;
      margin-bottom: 1rem;
      width: 100%;
      //   background-color: #222;
      .viewtitle {
        @include font_size(b04);
        font-weight: 500;
        color: #888;
        width: 30%;
        text-align: right;
      }
      .viewValue {
        width: 70%;
        @include font_size(b04);
        font-weight: 500;
        color: #222;
      }
    }
  }
}
