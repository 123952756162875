@import "../../../sass/generalUtility";
@import "../../../sass/colorPalette";
@import "../../../sass/fontSizeUtility";

.PatientDetailsViewPage {
  > .title {
    @include font_size(b01);
    text-align: center;
    margin-bottom: 2rem;
  }
  > .detailscontentBox {
    border-radius: 4px;
    display: grid;
    grid-template-columns: 3fr 3fr;
    background-color: #eee;
    @include font_size(b03);
    padding: 1rem;
    row-gap: 1rem;
  }
}
