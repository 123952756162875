@import "../../sass/generalUtility";
@import "../../sass/buttonModules";
@import "../../sass/widgetUtility";
@import "../../sass/fontSizeUtility";
@import "../../sass/utility.scss";

.PatientInteractionPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  align-items: start;
  justify-content: center;
  .PatientInteractionPageContent {
    position: relative;

    padding: 2rem;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 50%;
    .Elements {
      .ElementsLabel {
        @include font_size(b04);
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .dateTime {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        gap: 1rem;
      }
      > input[type="textarea"] {
        width: 100%;
        height: 6rem;
      }
      > input[type="text"] {
        width: 100%;
        height: 2.5rem;
      }
    }
    .closeIcon {
      position: absolute;
      cursor: pointer;
      top: 1%;
      right: 2%;
      &:hover {
        color: #555;
      }
    }
    .title {
      @include font_size(h01);
      margin-bottom: 3rem;
      text-align: center;
      font-weight: 600;
    }
    .interactionContent {
      width: 100%;
      display: grid;
      column-gap: 2rem;
      row-gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
      .detailBoxHead {
        @include font_size(b04);
        font-weight: 600;
      }

      .bigBtn {
        display: flex;
        justify-content: center;

        > button {
          width: 40%;
          display: flex;
          justify-content: center;
          padding: 8px 16px;
          border: none;
          background-color: $primary_color-1;
          border: 1px solid $primary_color-1;
          color: #fff;
          @include font_size(b04);
          border-radius: 3px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-right: 1rem;
        }
      }
      .buttonBox {
        width: 100%;
        text-align: end;
        margin-top: 1rem;
        button {
          width: 10rem;
          padding: 8px 16px;
          border: none;
          background-color: $primary_color-1;
          border: 1px solid $primary_color-1;
          color: #fff;
          @include font_size(b04);
          border-radius: 3px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-right: 1rem;
        }
        :nth-child(2) {
          color: $primary_color-1;
          background-color: #fff;
        }
      }
      .detailBoxBody {
        @include font_size(b04);
        display: flex;
        align-items: center;
        .noStyle {
          @include font_size(b04);
          display: flex;
          gap: 0.5rem;
          align-items: center;
          margin: 0 0.5rem;
          border: none;
          cursor: pointer;
          background-color: inherit;
        }
        .invalidNumber {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin: 0 3rem;
        }
        .checkboxes {
          display: flex;
          gap: 1rem;
          span {
            display: flex;
            gap: 0.5rem;
          }
        }
        .callStatusBox {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          button {
            padding: 0.5rem;
            background-color: inherit;
            border: 1px solid #000;
          }
        }
        .remarks {
          display: flex;
          gap: 0.5rem;
          position: relative;
          .remarksBtn {
            cursor: pointer;
          }
          .remarksDrop {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;
            background-color: #eee;
            position: absolute;
            right: -2%;
            top: 0;
            transform: translate(100%, -50%);
            > input {
              height: 6rem;
            }
          }
        }
      }
      .phoneCarrier {
        display: flex;
        gap: 1rem;
        > input {
          padding: 0.5rem;
        }
      }
    }
  }
}
