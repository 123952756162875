@import "../../../../sass/fontSizeUtility";
@import "../../../../sass/buttonModules";
@import "../../../../sass/colorPalette";

.addDefaultgoals {
  margin-left: 1rem;
  border: 1px solid #000;
  .options {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 30vh;
    overflow-y: scroll;
    li {
      border-bottom: 1px solid #000;
      padding: 10px;
      @include font_size(b04);

      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    > p {
      @include font_size(b04);
      text-align: center;
    }
  }
}
