@import "./fontSizeUtility";
@import "./colorPalette";

$height: 2vw;

@mixin error-icon-styles {
  width: 1.3vw;
  height: auto;
  aspect-ratio: 1;
  cursor: pointer;
  transition: all 350ms ease-in-out;
  outline: none;
  path {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    fill: $error-color;
    &:hover {
      fill: #f00;
    }
  }
}

@mixin widgets-title {
  position: relative;
  display: inline-block;
  @include font_size(b03);
  color: #1e1f5f;
  margin-bottom: 0.3vw;
  .error-icon {
    position: absolute;
    top: 50%;
    left: calc(100% + 0.3vw);
    transform: translateY(-50%);
    @include error-icon-styles;
  }
}

@mixin height-of-widget {
  height: $height;
}

@mixin widget-padding {
  padding-left: 3%;
}

@mixin border-properties {
  border-radius: 0.15vw;
  border-width: 1px;
  border-style: solid;
}

@mixin widget-font-size {
  @include font_size(b04);
}

@mixin default-border-color-of-widget {
  border-color: #505050;
}
@mixin error-border-color-of-widget {
  border-color: $error-color;
}
@mixin active-border-color-of-widget {
  border-color: $widget-color;
}

@mixin place-holder-styles {
  color: #d0d0d0;
}
