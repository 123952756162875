@import "../sass/fontSizeUtility";
@import "../sass/widgetUtility";
@import "../sass/sizes";
@import "../sass/colorPalette";

.inputTextAreaContainer1 {
  width: 100%;

  > .inputTextArea {
    width: 100%;

    > .title {
      @include widgets-title;
    }
    > textarea {
      width: 100%;
      height: calc(var(--heightMultiplier) * $height);
      outline: none;
      padding-top: 0.5vw;
      @include widget-padding;
      @include border-properties;
      @include default-border-color-of-widget;
      @include widget-font-size;
      font-family: "Inter";
      resize: none;

      &:focus {
        @include active-border-color-of-widget;
      }
      &::placeholder {
        @include place-holder-styles;
      }
    }
    &.invalid {
      > textarea {
        @include error-border-color-of-widget;
        &:focus {
          @include active-border-color-of-widget;
        }
      }
    }
  }
}
