@import "../../sass/generalUtility";
@import "../../sass/buttonModules";
@import "../../sass/widgetUtility";
@import "../../sass/fontSizeUtility";

@import "../../sass/sizes";
@import "../../sass/colorPalette";

.container-patients {
  @include layout-padding-property();
  padding-bottom: 2rem;
  .pageTitle {
    @include page-title-styles();
  }
  .viewPopUpCont {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      @include font_size(b01);
      font-weight: 500;
    }
    .buttonsContainer {
      width: 100%;
      display: flex;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: end;
      > button {
        border: none;
        @include font_size(b02);
        padding: 0.8rem;
        outline: none;
        transition: background-color 0.3s;
        border-radius: 3px;
        cursor: pointer;
      }
      > button:first-child {
        margin-right: 1rem;
        background-color: $primary_color-1;
        color: #fff;
        &:hover {
          background-color: $primary_color-dark-1;
        }
      }
      > button:last-child {
        &:hover {
          background-color: #ddd;
        }
      }
    }
  }
  .advanceSearchBox {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
    .box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      //   align-items: baseline;
      .head {
        @include font_size(b03);
      }
      > input {
        width: 100%;
        height: 2.5rem;
        padding: 0.7rem;
        border: 1px solid #000;
      }
    }
  }
  .buttonsWrap {
    display: flex;
    gap: 1rem;
    justify-content: end;
    align-items: center;
    margin-bottom: 1rem;
  }
  input[type="checkbox"] {
    appearance: none; /* Removes default styles */
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background-color: white; /* Default white background */
    border: 2px solid black; /* Black border when unchecked */
    border-radius: 4px; /* Optional: Rounded corners */
    cursor: pointer;
    display: inline-block;
    position: relative;
  }

  input[type="checkbox"]:checked {
    background-color: #7a5dda;
    border-color: #7a5dda;
  }

  input[type="checkbox"]:checked::before {
    content: "✔";
    color: white;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .selectWrap {
    display: flex;
    margin-top: 18px;
    width: 13rem;
  }
}
