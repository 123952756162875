@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/buttonModules";

.loadeligible {
  @include layout-padding-property();
  margin-bottom: 1rem;
  > .title {
    @include page-title-styles();
    // margin-bottom: 5rem;
  }
  .tableBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 5rem;
    justify-content: center;
    .box {
      background-color: #eee;
      padding: 2rem;
      height: 100%;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .steps {
        @include font_size(b01);
        font-weight: 600;
        margin-bottom: 2rem;
        text-decoration: underline;
      }
      > ol {
        > li {
          //   list-style-type: none;
          list-style-position: inside;
          line-height: 3px;
          @include font_size(b04);
          > span {
            font-weight: 600;
          }
        }
      }
      .btnWrap {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        > button {
          padding: 1rem 0.8rem;
          @include font_size(b02);
          background-color: $primary_color-1;
          color: #fff;
          border: none;
          border-radius: 5px;
        }
      }
      .note {
        @include font_size(b04);

        color: #f00;
        > span {
          font-weight: 600;
        }
      }
    }
    .addFile {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .uploadelement {
        .head {
          @include font_size(b03);
          font-weight: 600;
          margin-bottom: 0.5rem;

          > span {
            color: #f00;
          }
        }
        .body {
        }
        .inputBody {
          display: grid;
          grid-template-columns: 1fr 3fr;
          gap: 1rem;
          > input {
            width: 100%;
            height: 2.5rem;
          }
        }
      }
      .chooseCsv {
        align-self: center;
        padding: 1rem 0.8rem;
        @include font_size(b02);
        background-color: $primary_color-1;
        color: #fff;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        input {
          display: none;
        }
      }
    }
    .instructions {
      > ol {
        > li {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
