@import "../sass/fontSizeUtility";

.dropDownContentContainer {
  width: 100%;
  border: 1.5px solid #888888;
  border-radius: 4px;
  background-color: #fff;
  .title {
    @include font_size(b03);
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1.5px solid #888888;
    .icon {
      margin-right: 1rem;
      padding-top: rem;
    }
  }
  .body {
    padding: 1rem;
  }
}
