@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/buttonModules";
.serviceLog {
  @include layout-padding-property();
  padding-bottom: 1rem;
  .title {
    @include page-title-styles();
  }
  .viewPopUpCont {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      @include font_size(b01);
      font-weight: 500;
    }
    .buttonsContainer {
      width: 100%;
      display: flex;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: end;
      > button {
        border: none;
        @include font_size(b02);
        padding: 0.8rem;
        outline: none;
        transition: background-color 0.3s;
        border-radius: 3px;
        cursor: pointer;
      }
      > button:first-child {
        margin-right: 1rem;
        background-color: $primary_color-1;
        color: #fff;
        &:hover {
          background-color: $primary_color-dark-1;
        }
      }
      > button:last-child {
        &:hover {
          background-color: #ddd;
        }
      }
    }
  }
  .advanceSearchBox {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;
    .box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      //   align-items: baseline;
      .head {
        @include font_size(b05);
      }
      > input {
        width: 100%;
        height: 2.5rem;
        padding: 0.7rem;
        border: 1px solid #000;
      }

      .checkboxwrap {
        display: flex;
        align-items: center;
        gap: 1rem;
        .checkboxContainer {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          > p {
            @include font_size(b05);
          }
          > input {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
      }
      .select2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // flex-direction: column;
        gap: 1rem;
        > span {
          > input {
            width: 100%;
            height: 2.5rem;
            padding: 0.7rem;
            border: 1px solid #000;
          }
        }
      }
    }
  }
  .buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
    > button {
      @include font_size(b02);
      padding: 0.5rem 1rem;
      color: #fff;
      background-color: $primary_color-1;
      border: none;
      border-radius: 4px;
      &:hover {
        background-color: $primary_color-light-1;
        cursor: pointer;
      }
    }
  }
  .servicesBody {
    .servicesButtons {
      display: flex;
      justify-content: end;
      gap: 1rem;
      margin: 2rem 0;
      > button {
        @include font_size(b05);
        padding: 0.5rem 1rem;
        background-color: $primary_color-1;
        color: #fff;
        border: none;
        border-radius: 4px;
        &:hover {
          background-color: $primary_color-light-1;
          cursor: pointer;
        }
      }
    }
    .serviceLogTable {
      .note {
        @include font_size(b05);
        font-weight: 600;
        margin-bottom: 1rem;
        color: #ff7801;
      }
      .grid2 {
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        border: 1px solid #000;
      }
      .tableElement {
        border-right: 1px solid #000;
        padding: 0.5rem;
        @include font_size(b05);
      }
      .grid1 {
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        border: 1px solid #000;
        &.header {
          font-weight: 700;
        }
      }
      .tableBodyElement {
        overflow: scroll;
        border-right: 1px solid #000;
        padding: 0.5rem;
        @include font_size(b05);
      }
      .tableBodyElementAction {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0.5rem;
        > input {
          width: 21px;
          height: 21px;
        }
        .icon {
          cursor: pointer;
        }
      }
      .grid3 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        border-bottom: 1px solid #000;
        .subComponent {
          border-right: 1px solid #000;
          padding: 0.5rem;
          @include font_size(b05);
          overflow-x: scroll;
        }
        .subComponentBtn {
          border-right: 1px solid #000;
          align-items: center;

          display: flex;
          justify-content: center;
          gap: 1rem;
          .icons {
            cursor: pointer;
          }
        }
      }
      .totalcpmd {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        @include font_size(b05);
        font-weight: 600;
        .total {
          padding: 0.5rem;
          text-align: end;
          border-right: 1px solid #000;
        }
        .totalTime {
          padding: 0.5rem;
          text-align: start;
          border-right: 1px solid #000;
        }
      }
    }
  }
}
.serviceLog .rs-picker-error > .rs-picker-input-group.rs-picker-input-group {
  border-color: #000;
  border-radius: 0;
}
.serviceLog .rs-input-group {
  border-color: #000;
  border-radius: 0;
  height: 2.7rem;
}
