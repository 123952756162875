@import "../sass/sizes";
@import "../sass/fontSizeUtility";

input.twoFA_InputFieldContainer {
  width: 2.5vw;
  height: auto;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font_size(b04);
  border: 1px solid #888;
  border-radius: 4px;
  text-align: center;
  &::placeholder {
  }
}
