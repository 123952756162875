@import "../sass/fontSizeUtility";
@import "../sass/widgetUtility";
@import "../sass/sizes";
@import "../sass/colorPalette";

.inputCheckFieldContainer1 {
  width: 100%;

  > .inputCheckField1 {
    display: flex;
    width: 100%;
    cursor: pointer;
    input {
      cursor: pointer;
      accent-color: $primary_color-1;
      width: auto;
      height: calc(1.3rem + 0.2vw);
      overflow: hidden;
      aspect-ratio: 1;
      margin-right: 1rem;
      @include default-border-color-of-widget;
      border-radius: calc(0.2vw);
    }

    @include widget-font-size;
    &:hover {
      input {
        @include active-border-color-of-widget;
        &:not(:checked) {
          // background-color: $primary_color-light-4;
          box-shadow: inset 0 0 0.2rem 0.1rem $primary_color-dark-1;
        }
      }
    }
  }
}
