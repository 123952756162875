@import "../sass/generalUtility";
@import "../sass/buttonModules";
@import "../sass/widgetUtility";
@import "../sass/fontSizeUtility";
@import "../sass/utility.scss";

.mainLoyout {
  @include layout-padding-property();
  .title {
    @include page-title-styles();
  }
  .viewPopUpCont {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      @include font_size(b01);
      font-weight: 500;
    }
    .buttonsContainer {
      width: 100%;
      display: flex;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: end;
      > button {
        border: none;
        @include font_size(b02);
        padding: 0.8rem;
        outline: none;
        transition: background-color 0.3s;
        border-radius: 3px;
        cursor: pointer;
      }
      > button:first-child {
        margin-right: 1rem;
        background-color: $primary_color-1;
        color: #fff;
        &:hover {
          background-color: $primary_color-dark-1;
        }
      }
      > button:last-child {
        &:hover {
          background-color: #ddd;
        }
      }
    }
  }
  .filterAndContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem;
    .filter {
      display: flex;
      gap: 2rem;

      .filterElemt {
        width: 20rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        > p {
          @include font_size(b03);
        }
        > input {
          height: 2.5rem;
          padding-left: 0.5rem;
          border: 1px solid #000;
        }
      }
    }
  }
  .contentBox {
    position: relative;
    max-height: 500px;
    overflow-y: auto;

    .contentGrid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      border: 1px solid #888;
      background-color: #fff;

      &.header {
        position: sticky;
        top: 0;
        z-index: 10;
        font-weight: 700;
        background-color: #fff;
      }

      .griElement {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: scroll;
        border-right: 1px solid #888;
        @include font_size(b03);
        padding: 0.5rem;
      }
    }

    .contentGrid.contentBody {
      border-top: none;

      .buttonBox {
        display: flex;
        align-items: center;
        justify-content: center;

        > button {
          border: none;
          border-radius: 50%;
          background-color: #fff;
          padding: 0.8rem;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          transition: all 350ms ease-in-out;

          &:hover {
            background-color: #ddd;
          }
        }
      }
    }
  }
}
