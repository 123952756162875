.OTP_InputContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  width: fit-content;
  margin: 0 auto;
  padding: 3rem 0;

  // justify-tracks: ;
}
