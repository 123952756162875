@import "../sass/colorPalette";
@import "../sass/buttonModules";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
.callTaskList {
  @include layout-padding-property();
  @include topbar-margin-property();
  .RTMtitle {
    @include page-title-styles;
    margin-bottom: 5rem;
  }
  .nav {
    display: flex;
    border: 1px solid #000;
    width: fit-content;
    border-radius: 4px;
    transition: all 0.1s ease;
    margin-bottom: 2rem;

    .navHeader {
      @include font_size(b03);
      font-weight: 400;
      padding: 1rem;
    }
    .navHeaderSelected {
      color: #fff;
      background-color: $primary_color-1;
      border: none;
      //   border-radius: 0 4px 4px 0;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
}
