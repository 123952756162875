@import "../sass/colorPalette";
@import "../sass/buttonModules";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";

.PatientTransferBox {
  display: grid;
  grid-template-columns: 5fr 1fr;
  border: 1px solid #000;
  .headingTitle {
    font-weight: 400;
    padding: 1rem;
    @include font_size(b03);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .cheackBoxAll {
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detailsSec {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 5fr 1fr;
    height: 15rem;
    overflow-y: scroll;
    .details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      @include font_size(b05);
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      .extraBold {
        font-weight: 600;
      }
    }
    .patientCheckBox {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #000;
    }
    .emptyDisplay {
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_size(b02);
    }
  }
  .transfercheckbox {
    transform: scale(1.5);
  }
}
