@import "../../sass/generalUtility";
.pageTitle {
  @include page-title-styles();
}
.navFilter {
  display: grid;
  width: 30%;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 1rem;
  .box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    //   align-items: baseline;
    .head {
      @include font_size(b03);
      white-space: nowrap;
    }
    > input {
      width: 100%;
      height: 2.5rem;
      padding: 0.7rem;
      border: 1px solid #000;
    }

    .checkbox {
      > input {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
    .select2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // flex-direction: column;
      gap: 0.5rem;
    }
  }
}
