@import "../../sass/fontSizeUtility";
@import "../../sass/generalUtility";
@import "../../sass/colorPalette";
.vitalspageContainer {
  border: 1px solid #888;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
  .vhead {
    padding: 0.3rem;
    background-color: #fff;
    border-bottom: 1px solid #888;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .vtitle {
      color: #222;
      @include font_size(b04);

      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.18px;
    }
    .vnav {
      display: flex;
      gap: 1rem;
      padding: 0.5rem;
      .vnavBtn {
        display: flex;
        padding: 5px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border: none;
        outline: none;
        border-radius: 2px;
        background-color: $primary_color-1;
        white-space: nowrap;
        > p {
          @include font_size(b05);

          font-weight: 600;
          color: #fff;
        }
        &:hover {
          background-color: $primary_color-dark-1;
          cursor: pointer;
        }
      }
    }
    .boxHead {
      padding: 0.3rem;
      background-color: #fff;
      border-bottom: 1px solid #888;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .headTitle {
        color: #222;
        @include font_size(b04);

        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
      }
      .boxBody {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        .boxBtn {
          display: flex;
          padding: 5px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          border: none;
          outline: none;
          border-radius: 2px;
          background-color: $primary_color-1;
          white-space: nowrap;
          > p {
            @include font_size(b05);

            font-weight: 600;
            color: #fff;
          }
          &:hover {
            background-color: $primary_color-dark-1;
            cursor: pointer;
          }
        }
      }
    }
    .boxBtn {
      display: flex;
      padding: 5px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border: none;
      outline: none;
      border-radius: 2px;
      background-color: $primary_color-1;
      white-space: nowrap;
      > p {
        @include font_size(b05);

        font-weight: 600;
        color: #fff;
      }
    }
    .refreshBtn {
      width: 25rem;
    }
  }
  .vBody {
    .vtab {
      display: flex;
      gap: 1rem;
      padding: 1rem;
      background-color: #fff;
      .tabBtn {
        display: flex;
        height: 30px;
        padding: 8px 10px;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: #e7e7e7;
        > p {
          color: #9b86e3;
          font-weight: 500;
          @include font_size(b04);
        }
      }
      .active {
        background: rgba(122, 93, 218, 0.75);
        > p {
          color: #fff;
        }
      }
    }
    .boxContainer {
      .boxGridBody {
        padding: 1rem;

        .note {
          @include font_size(b04);
          margin-bottom: 1rem;
        }
        .subNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          padding: 1rem;
          .period {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            .periodTxt {
              @include font_size(b04);
              color: #222;
              font-family: 700;
            }
            > select {
              width: 8rem;
              padding: 4px;
            }
          }
          .combineicon {
            background-color: $primary_color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.5px solid #888;
            border-radius: 2px;
            > span {
              padding: 1px;
              height: 100%;
              width: 100%;
            }
            .grey {
              background-color: #eee;
            }
            .blue {
              background-color: $primary_color-1;
            }
          }
          .weekgrid {
            display: flex;
            border: 0.5px solid #888;
            > button {
              border: none;
              &:hover {
                cursor: pointer;
              }
            }

            .dur {
              display: flex;
              justify-content: center;
              align-items: center;
              @include font_size(b05);
              color: #222;
              font-family: 400;
              border-right: 0.5px solid #888;
              padding: 4px;
            }
            .dur:last-child {
              border-right: none;
            }
            .activeDuration {
              background-color: $primary_color-1;
              color: #fff;
            }
          }
        }
        .gridBody {
          display: grid;
          grid-template-columns: repeat(14, 1fr);
          border: 1px solid #888;
          border-radius: 4px;
          height: 200px;
          padding-bottom: 10rem;
          overflow: scroll;
        }

        .gridHeader {
          display: contents;
        }

        .gridHead {
          position: sticky;
          top: 0;
          background-color: #eee;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #888;
          border-bottom: 1px solid #888;
          @include font_size(b04);
          padding: 0.5rem;
          color: #222;
          font-weight: 500;
          z-index: 1; /* Ensure header stays on top of the content */
        }

        .gridContent {
          display: contents; /* Makes sure content is still part of the grid structure */
        }

        .gridBox {
          display: flex;
          justify-content: center;
          @include font_size(b04);
          border-right: 1px solid #888;
          border-bottom: 1px solid #888;
          align-items: center;
          font-weight: 400;
          padding: 1rem;
          color: #222;
        }

        .grid12 {
          grid-template-columns: repeat(12, 1fr);
        }
        .grid7 {
          grid-template-columns: repeat(7, 1fr);
        }
      }
    }
  }
}
