@import "./_colorPalette.scss";
@import "./fontSizeUtility";
.tabElement {
  padding: 0.7vw 1.5vw;
  cursor: pointer;
  @include font_size(b03);
  transform: all 350ms ease-in-out;
  &:hover {
    background-color: #f8f8f8;
  }
  &.active {
    background-color: $primary_color-1;
    color: #fff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}
