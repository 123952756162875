@import "../sass/generalUtility";
@import "../sass/buttonModules";
@import "../sass/widgetUtility";
@import "../sass/fontSizeUtility";
@import "../sass/utility.scss";

.mainLoyout {
  .wrapselect {
    margin-top: 1rem;
    width: 40%;
  }
  @include layout-padding-property();
  .title {
    @include page-title-styles();
  }
  .vBody {
    .vtab {
      display: flex;
      gap: 1rem;
      padding: 1rem;
      background-color: #fff;
      .tabBtn {
        display: flex;
        height: 30px;
        padding: 8px 10px;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: #e7e7e7;
        > p {
          color: #9b86e3;
          font-weight: 500;
          @include font_size(b04);
        }
      }
      .active {
        background: rgba(122, 93, 218, 0.75);
        > p {
          color: #fff;
        }
      }
    }
    .Datebox {
      display: flex;
      align-items: center;
      justify-content: end;
      width: 92%;
      .head {
        @include font_size(b04);
        margin-right: 0.5rem;
      }
    }
    .boxContainer {
      .boxGridBody {
        padding: 0.5rem;

        .note {
          @include font_size(b04);
          margin-bottom: 1rem;
        }
        .subNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          padding: 0.5rem;
          .period {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            .periodTxt {
              @include font_size(b04);
              color: #222;
              font-family: 700;
            }
            > select {
              width: 8rem;
              padding: 4px;
            }
          }
          .combineicon {
            background-color: $primary_color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.5px solid #888;
            border-radius: 2px;
            > span {
              padding: 1px;
              height: 100%;
              width: 100%;
            }
            .grey {
              background-color: #eee;
            }
            .blue {
              background-color: $primary_color-1;
            }
          }
          .weekgrid {
            display: flex;
            border: 0.5px solid #888;
            > button {
              border: none;
              &:hover {
                cursor: pointer;
              }
            }

            .dur {
              display: flex;
              justify-content: center;
              align-items: center;
              @include font_size(b05);
              color: #222;
              font-family: 400;
              border-right: 0.5px solid #888;
              padding: 4px;
            }
            .dur:last-child {
              border-right: none;
            }
            .activeDuration {
              background-color: $primary_color-1;
              color: #fff;
            }
          }
        }
        .gridBody {
          display: grid;
          grid-template-columns: repeat(14, 1fr);
          border: 1px solid #888;
          border-radius: 4px;
          max-height: 650px;
          padding-bottom: 10rem;
          overflow: scroll;
        }

        .gridHeader {
          display: contents;
        }

        .gridHead {
          position: sticky;
          top: 0;
          background-color: #eee;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #888;
          border-bottom: 1px solid #888;
          @include font_size(b04);
          padding: 0.5rem;
          color: #222;
          font-weight: 500;
          z-index: 1;
        }

        .gridContent {
          display: contents;
        }

        .gridBox {
          display: flex;
          justify-content: center;
          @include font_size(b04);
          border-right: 1px solid #888;
          border-bottom: 1px solid #888;
          align-items: center;
          font-weight: 400;
          padding: 1rem;
          color: #222;
        }

        .grid12 {
          grid-template-columns: repeat(12, 1fr);
        }
        .grid7 {
          grid-template-columns: repeat(7, 1fr);
        }
      }
    }
  }
}
