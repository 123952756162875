@import "../sass/fontSizeUtility";
@import "../sass/colorPalette";

.dropDownSelectorContainer {
  background-color: #fff;
  padding: 2rem 1rem;
  width: 22%;
  padding-bottom: 2.5rem;
  margin-bottom: 0.5rem;

  .selector {
    .details {
      @include font_size(b02);
      width: 100%;
      height: 1rem;
    }
    // background-color: #f00;
    // display: flex;
    // position: relative;

    .title {
      display: flex;
      width: 100%;
      border: none;
      justify-content: space-between;
      background-color: #eee;
      @include font_size(b04);
      color: #888888;
      // padding-top: .5rem;
      margin-bottom: 1rem;
      p {
        padding-top: 0.5rem;
      }
      button {
        background-color: $primary_color-1;
        color: #fff;
        padding: 2px 6px;
        border-radius: 2px;
        border: none;
      }
    }
    select {
      color: #000;
      width: 100%;
      background-color: #fff;
      border-radius: 4px;
      padding: 0.5rem;

      // margin-bottom: .4rem;
      //  border: none;
      // -moz-appearance:none;
      // -webkit-appearance:none;
      // appearance:none;
      // background-color: #eee;
      // padding: 1px;
      // width: 100%;
      // &:focus{
      //     outline: none;
      // }
      // .default{
      //         display: none;
      // }

      .default {
        display: none;
      }

      .options {
        // display: block;
        // padding: 1rem;
        @include font_size(b04);
        // padding: 1rem;
      }
    }

    .name {
      @include font_size(b02);
      width: 100%;
      font-weight: 600;
    }
  }
}
