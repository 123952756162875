@import "./fontSizeUtility";

.select-with-search {
  position: relative;
  width: 100%;
  margin-bottom: 3rem;

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #000;
    // z-index: 1;

    // display: flex;
    .dropFlex {
      display: flex;
      input[type="number"] {
        width: 100%;
        padding: 5px;
        border: none;
        outline: none;
      }
      .close {
        // display: flex;
        align-self: end;
        background-color: #fff;
        font-weight: bold;
        border: none;
        outline: none;
        padding: 0;
        @include font_size(b02);
      }
    }

    .options {
      list-style-type: none;
      padding: 0;
      margin: 0;
      max-height: 20rem;
      // height: 10rem;
      // height: 15rem;
      overflow-y: scroll;
      li {
        // z-index: 20;
        border-top: 1px solid #000;
        padding: 10px;
        @include font_size(b04);

        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}
