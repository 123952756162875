@import "./fontSizeUtility";

.timeInputContainer {
  display: flex;
  gap: 5px;
  @include font_size(b02);
  .select-with-search .dropdown {
    z-index: 0;
  }
}

.timeInput {
  width: 50px;
  text-align: center;
  padding: 5px;
}

.popElement {
  margin-bottom: 10px;
}
