@import "../sass/generalUtility";
@import "../sass/buttonModules";
@import "../sass/fontSizeUtility";
@import "../sass/widgetUtility";
@import "../sass/sizes";
@import "../sass/colorPalette";
.patients {
  @include layout-padding-property();
  .patientsContainer {
    padding-bottom: 1.5rem;
    .pageTitle {
      @include page-title-styles();
    }
    .tabWidgetContainer {
      position: relative;
      .addButton {
        position: absolute;
        top: -1rem;
        right: 0;
        @include font_size(b03);
        font-weight: 600;
        background-color: "#E7E7E7";
        color: "#222222";
        border-radius: 0;
        padding: 1rem;
        border: none;
      }
    }
    .patientsBasicInfoContainer1 {
      grid-column-gap: 1rem;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(3, calc(3rem + 2.5vw));
      grid-column-gap: 1rem;
    }
    .seperator-line {
      background-color: #6d6d71;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .patientsBasicInfoContainer2 {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(2, calc(3rem + 2.5vw));
      grid-column-gap: 1rem;
    }
    .patientsBasicInfoContainer3 {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(1, calc(3rem + 2.5vw));
      grid-column-gap: 1rem;
    }

    .buttonContainer {
      width: calc(10rem + 6vw);
      margin-top: calc(1.2rem + 1vw);
      button {
        @include login-button-styles();
      }
    }
  }
}
.patienPhoto {
  width: 100%;
  padding-top: 0.5rem;

  // display: flex;
  // align-items: center;
  // flex-direction: column;
  .title {
    @include widgets-title;
  }
  > input {
    width: 100%;
    outline: none;
    @include height-of-widget;
    @include widget-padding;
    @include border-properties;
    @include default-border-color-of-widget;
    @include widget-font-size;
    padding: 0.2rem;

    &:focus {
      @include active-border-color-of-widget;
    }

    &::placeholder {
      @include place-holder-styles;
      display: flex;
      align-content: center;
    }
  }
}
