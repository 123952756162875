@mixin size400 {
  @media screen and (max-width: 400px) {
    @content;
  }
}

@mixin size500 {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin size600 {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin size700 {
  @media screen and (max-width: 700px) {
    @content;
  }
}

@mixin size800 {
  @media screen and (max-width: 800px) {
    @content;
  }
}

@mixin size900 {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin size1000 {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin size1100 {
  @media screen and (max-width: 1100px) {
    @content;
  }
}

@mixin size1200 {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin size1300 {
  @media screen and (max-width: 1300px) {
    @content;
  }
}

@mixin size1400 {
  @media screen and (max-width: 1400px) {
    @content;
  }
}

@mixin size1500 {
  @media screen and (max-width: 1500px) {
    @content;
  }
}
@mixin size1600 {
  @media screen and (max-width: 1600px) {
    @content;
  }
}
@mixin size1700 {
  @media screen and (max-width: 1700px) {
    @content;
  }
}
@mixin size1800 {
  @media screen and (max-width: 1800px) {
    @content;
  }
}
@mixin size1900 {
  @media screen and (max-width: 1900px) {
    @content;
  }
}

@mixin size2000 {
  @media screen and (max-width: 2000px) {
    @content;
  }
}
