@import "../../../sass/fontSizeUtility";
@import "../../../sass/buttonModules";
@import "../../../sass/colorPalette";
.popUpDelete {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .popupContent {
    background-color: #fff;
    padding: 30px;
    @include font_size(b01);
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;

    p {
      margin-bottom: 30px;
    }

    .popupActions {
      display: flex;
      justify-content: center;
      gap: 20px;

      button {
        padding: 8px 16px;
        border: none;
        background-color: $primary_color-1;
        color: #fff;
        @include font_size(b01);

        border-radius: 3px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: $primary_color-dark-1;
        }
      }
    }
  }
}

.containerGrid {
  display: grid;
  grid-template-columns: 10fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  // padding-bottom: 3rem;

  .displayGrid {
    display: grid;
    padding-top: 1rem;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    margin: 1rem 0 0 3rem;
    padding-bottom: 3rem;
    // margin-left: 3rem;
    position: relative;

    &::before {
      content: "";
      height: 1rem;
      width: 1rem;
      background-color: $primary_color-1;
      border-radius: 50%;
      position: absolute;
      left: calc(-1.5rem + 0.5px);
      transform: translateX(-50%);
      box-shadow: 0 0 10px $primary_color-light-1;
    }
    &::after {
      content: "";
      height: 100%;
      width: 1px;
      background-color: $primary_color-1;
      position: absolute;
      // border-radius: 50%
      left: -1.5rem;
      top: 1rem;
    }
    .displayGnrlElement {
      display: flex;
      gap: 0.2rem;

      .displayGnrlTitle {
        @include font_size(b05);
        font-weight: 600;
        color: #222;
        margin-bottom: 0.5rem;
      }
      .displayGnrlContent {
        @include font_size(b05);
      }
    }
  }
  .displayBtnGrid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3rem;
  }
}
