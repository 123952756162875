@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
.addshedule {
  .title {
    text-align: center;
    @include font_size(h01);

    margin-bottom: 2rem;
  }
  .contai {
    display: grid;
    grid-template-columns: 2fr 1fr;
    .elementsSides {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      .Elements {
        .ElementsLabel {
          @include font_size(b04);
          margin-bottom: 0.5rem;
        }
        .dateTime {
          display: grid;
          grid-template-columns: 2fr 2fr 1fr;
          gap: 1rem;
        }
        > input[type="textarea"] {
          width: 100%;
          height: 6rem;
        }
        > input[type="text"] {
          width: 100%;
          height: 2.5rem;
        }
        > .field {
          height: 2.5rem;
          width: 100%;
          @include font_size(b04);
        }
      }
    }
  }
  .sheduleButton {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 2rem;
    > button {
      border: none;
      @include font_size(b03);
      width: 8rem;
      padding: 0.5rem;
      outline: none;
      transition: background-color 0.3s;
      border-radius: 3px;
      cursor: pointer;
    }
    > button:first-child {
      margin-right: 1rem;
      background-color: $primary_color-1;
      color: #fff;
      &:hover {
        background-color: $primary_color-dark-1;
      }
    }
  }
}
