$primary_color-1: #7a5dda;
$primary_color-dark-1: #624aae; //dark 1
$primary_color-dark-2: #493883; //dark 2
$primary_color-dark-3: #312557; //dark 3
$primary_color-dark-4: #18132c; //dark 4
$primary_color-light-1: #957de1; //light 1
$primary_color-light-2: #af9ee9; //light 2
$primary_color-light-3: #cabef0; //light 3
$primary_color-light-4: #e4dff8; //light 4
$error-color: #e34443;
$widget-color: $primary_color-1;
