@import "./_fontSizeUtility.scss";

$topBarHeight: calc(6rem + 1.5vw);

@mixin layout-padding-property {
  padding-left: 2rem;
  padding-right: 2rem;
}
@mixin topBar-height-property {
  height: $topBarHeight;
}
@mixin topbar-margin-property {
  margin-top: calc($topBarHeight + 1vw);
}
@mixin page-title-styles {
  margin-top: calc($topBarHeight + 1vw);
  margin-bottom: 1vw;
  @include font_size("h04");
  font-weight: 600;
}

@mixin mute_properties {
  transition: all 0ms ease-in-out;
  &::after {
    position: absolute;
    width: 0;
    border-top: 1px solid #222;
    height: 2px;
    background-color: #fff;
    content: "";
    top: 32%;
    left: 32%;
    transform-origin: 0;
    transform: translate(0, -1px) rotateZ(45deg);
    transition: inherit;
  }
  &.disabled {
    background-color: #222;
    transition: all 350ms ease-in-out;
    &::after {
      width: 50%;
      transition: inherit;
    }
    svg {
      path {
        fill: #cccccc;
      }
    }
  }
}
