@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";

.sideBar {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: calc(6rem + 10vw);
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  transform-origin: left;
  transition: transform 350ms ease-in-out;
  transform: translateX(0%);
  &.hidden {
    transform-origin: left;
    transform: translateX(-100%);
  }
  .header {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: $primary_color-1;
    @include topBar-height-property();
    @include layout-padding-property();

    img {
      margin: 0;
    }

    .menu {
      cursor: pointer;
    }
    .logo {
      width: auto;
      margin-left: 1vw;
    }
  }
  .first-fold-props {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include font_size(b03);
    cursor: pointer;
  }
  .second-fold-props {
    // margin-top: 0.5rem;
    // margin-bottom: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include font_size(b04);
    cursor: pointer;
    min-width: 10vw;
  }
}
