@import "../../../sass/fontSizeUtility";
@import "../../../sass/buttonModules";
@import "../../../sass/colorPalette";
.careplandisplayContainer {
  .popUpDelete {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    .popupContent {
      background-color: #fff;
      padding: 30px;
      @include font_size(b01);
      font-weight: 500;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      text-align: center;

      p {
        margin-bottom: 30px;
      }

      .popupActions {
        display: flex;
        justify-content: center;
        gap: 20px;

        button {
          padding: 8px 16px;
          border: none;
          background-color: $primary_color-1;
          color: #fff;
          @include font_size(b01);

          border-radius: 3px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: $primary_color-dark-1;
          }
        }
      }
    }
  }
  .displayElement {
    display: grid;
    grid-template-columns: 9fr 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
    margin-top: 2rem;
    margin-left: 3rem;
    position: relative;
    &::before {
      content: "";
      height: 1rem;
      width: 1rem;
      background-color: $primary_color-1;
      border-radius: 50%;
      position: absolute;
      left: -2%;
      box-shadow: 0 0 10px $primary_color-light-1;
    }
    &::after {
      content: "";
      height: 100%;
      width: 1px;
      background-color: $primary_color-1;
      position: absolute;
      // border-radius: 50%
      left: -1.5%;
      top: 1rem;
    }
    .elementGrid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      row-gap: 1rem;
      .elementContent {
        .eleTitle {
          @include font_size(b04);
          font-weight: 600;
          color: #222;
          margin-bottom: 0.5rem;
        }
        .electnt {
          @include font_size(b04);
        }
        &.ICD_desc {
          width: 95%;
        }
        &.Goal {
          width: 95%;
        }
      }
    }
    .BtnGrid {
      justify-self: center;
    }
  }
}
