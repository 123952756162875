.gpt-sec {
  .gpt-table-container {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        background-color: #7a5dda;
        color: white;
        z-index: 1;
      }

      th,
      td {
        border: 1px solid #ccc;
        padding: 12px;
        text-align: left;
      }

      th {
        background-color: #7a5dda;
        color: white;
      }

      tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      tbody tr:hover {
        background-color: #ddd;
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
    }
  }
}
