@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/utility.scss";
.scheduleCalls {
  @include layout-padding-property;
  .pageTitle {
    @include page-title-styles;
  }
  .advanceSearchBox {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1.5rem;

    margin-bottom: 5rem;
    .box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      //   align-items: baseline;
      .head {
        @include font_size(b03);
      }
      > input {
        width: 100%;
        height: 2.5rem;
        // padding: 0.5rem;
        border: 1px solid #000;
      }
      .checkbox {
        > input {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
      .select2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
  .callsBody {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    border: 1px solid #888;
    .header {
      display: flex;
      padding: 1rem;
      align-items: end;
      justify-content: start;
      font-weight: 500;
      @include font_size("b04");
      border-right: 1px solid #888;
      border-bottom: 1px solid #888;
    }
    .callsElement {
      display: flex;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #888;
      border-bottom: 1px solid #888;

      @include font_size("b04");

      .field {
        display: flex;
        gap: 1rem;
        // height: rem;
        > input {
          height: 2.5rem;
        }
        .datePicker {
          height: 2.5rem;
        }
        .select100 {
          width: 100%;
        }
      }
    }
  }
}
