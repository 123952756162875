.buttonComponent {
  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    > .internalContainer {
      position: relative;
      > .loader {
        position: absolute;
        content: " ";
        top: 50%;
        right: calc(1rem + 100%);
        width: 3rem;
        height: 3rem;
        transform: translateY(-50%);
        > img {
          width: 100%;
          height: 100%;
          opacity: 0;
          &.rotate {
            opacity: 1;
            animation: rotation 1s infinite linear;
          }
        }
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
