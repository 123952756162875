@import "../../sass/generalUtility";
@import "../../sass/colorPalette";
@import "../../sass/fontSizeUtility";

.timerPopUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .timerContentPopUp {
    width: 50vw;
    height: 20rem;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    margin-top: calc($topBarHeight);

    border-radius: 8px;
    padding: 2rem;
    background: #fff;
    .content-text {
      @include font_size(h01);
      font-weight: 600;
      color: $primary_color-1;
      display: flex;
      margin-bottom: 1rem;
      gap: 1rem;
      > span {
        width: 40%;
        .selectsearch {
          width: 100%;
          // height: 2rem;
          border: 2px solid $primary_color-1;
          border-radius: 4px;
          .dropFlex {
            border-radius: 4px;
            .spanElementdiv {
              height: 2.8rem;
              border-radius: 4px;
              .selected {
                border-radius: 4px;
                // background-color: #fff;
                // padding: 0.5rem;
                // z-index: 0;
                // height: 100%;
                // width: 95%;
                // overflow: hidden;
                // @include font_size(b04);
                // border: none;
                // text-decoration: none;
                // outline: none;
              }

              .dropdown {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: #fff;
                z-index: 20;

                .options {
                  outline: 2px solid $primary_color-1;
                  border-bottom-right-radius: 4px;
                  border-bottom-left-radius: 4px;

                  li {
                    border-bottom: 1px solid #000;

                    &:hover {
                      background-color: #f0f0f0;
                    }
                  }
                  :last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .dialog {
      display: flex;
      gap: 2rem;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      .btn {
        background-color: $primary_color-1;
        color: #fff;
        border: none;
        @include font_size(b01);
        border-radius: 4px;
        width: 8rem;
        padding: 0.5rem;
        &:hover {
          background-color: $primary_color-light-1;
          cursor: pointer;
        }
      }
    }
  }
}
.signature-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 100%;

  .signature {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333;
  }

  input {
    width: 100%;
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.6rem;
    outline: none;
    transition: border 0.3s ease;

    &:focus {
      border-color: $primary_color-light-1;
    }
  }

  input[type="file"] {
    padding: 1px;
    border: none;
  }
  input[type="date"] {
    width: 20%;
  }
  .btnContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: end;
    button {
      background-color: $primary_color-1;
      color: #fff;
      font-size: 1.6rem;
      padding: 0.6rem 1.2rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background-color: $primary_color-light-1;
      }
    }
  }
}

.carePlanWrap {
  width: 100%;
  height: 20vh;
  .careTeamContainer {
    margin: 0 10rem;

    .pageTitle {
      @include page-title-styles();
    }
    .headerSection {
      background-color: #eee;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border: 1px solid #888888;
      border-radius: 8px;
      .element {
        // background-color: #EEE;
        padding: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub {
          @include font_size(b04);
          margin-bottom: 0.4rem;
          color: #888888;
        }
        .name {
          @include font_size(b01);
        }
        .time {
          @include font_size(h03);
          color: #505050;
          font-weight: 600;
          position: relative;
          // &::after {
          //   font-weight: normal;
          //   content: "Total Time Spent";
          //   position: absolute;
          //   top: -35%;
          //   left: 0;
          // }
        }
        &.element6 {
          flex-direction: row;
          gap: 1rem;
          .download-button {
            a,
            button {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              width: fit-content;
              text-decoration: none;
              height: 4rem;
              padding: 0.5vw 1.5vw;
              @include font_size(b03);
              outline: none;
              border: 1px solid $primary_color-1;
              background: $primary_color-1;
              color: #fff;
              border-radius: 0.3vw;
              transition: all 150ms ease-in-out;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
      .element7,
      .element8 {
        div.time {
          font-weight: normal;
          @include font_size(b04);
          color: #888888;
        }
        padding: 0.5rem 1rem 1rem 1rem;
        .stopwatch {
          position: relative;
        }
      }
      .element1 {
        flex-direction: row;
        gap: 1.5rem;
        justify-content: start;
        .profile-icon-wrap {
          background-color: #a9a9a9;
          border-radius: 50px;
          padding: 1rem;
        }
        .details {
          align-self: center;
          .sub {
            color: #7a5dda;
            font-weight: 600;
          }
          .name {
            color: #7a5dda;
            font-weight: 600;
            margin-bottom: 0.4rem;
          }
        }
      }
    }
    .body-section {
      width: 100%;
      height: 80vh;
      margin: 1.5rem 0 2rem;
      display: flex;
      gap: 1rem;

      .content-section {
        padding: 2rem;
        width: 100%;
        background-color: #eee;
        // height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        border-radius: 4px;
        height: 90%;
        overflow-y: scroll;

        .date-section {
          width: 100%;
          display: flex;
          gap: 6rem;
          .sub {
            @include font_size(b05);
            margin-bottom: 0.4rem;
            color: #888888;
          }
          .date {
            @include font_size(b01);
          }
        }

        // .dropDown {
        //   border: 2px solid #888888;
        //   border-radius: 4px;
        //   // margin-top: 2rem;

        //   .dropComponents {
        //     display: flex;
        //     gap: 2rem;
        //     background-color: #f00;
        //   }
        // }
      }
    }
  }
}
