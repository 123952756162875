.backDrop {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}

.backDropHide {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  &.hide {
    visibility: hidden;
  }
}
