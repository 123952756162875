@import "../../../sass/fontSizeUtility";
@import "../../../sass/colorPalette";
.DropContainerContainer {
  width: 100%;
  border: 1.5px solid #888888;
  border-radius: 4px;
  background-color: #fff;
  overflow: scroll;
  margin-bottom: 1rem;
  .title {
    @include font_size(b03);
    background-color: #eee;
    font-weight: 600;
    display: grid;
    grid-template-columns: 9fr 1fr;
    justify-content: space-between;
    overflow: hidden;
    // border: none;
    align-items: center;
    padding: 5px 0;
    .btnsflex {
      display: flex;
      gap: 1rem;
      padding-right: 1rem;
      .boxBtn {
        display: flex;
        padding: 3px 5px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border: none;
        outline: none;
        border-radius: 2px;
        background-color: $primary_color-1;
        white-space: nowrap;
      }
    }
    .fl {
      display: flex;
      align-items: center;
    }
    .icon {
      display: flex;
      align-items: center;
    }
  }
  .body {
    // padding-bottom: 1rem;
  }
}
