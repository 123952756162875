@import "../sass/fontSizeUtility";
@import "../sass/widgetUtility";
@import "../sass/sizes";
@import "../sass/colorPalette";

.inputTextFieldContainer1 {
  width: 100%;

  > .inputTextField {
    width: 100%;

    > .title {
      @include widgets-title;
    }
    > input {
      width: 100%;
      outline: none;
      @include height-of-widget;
      @include widget-padding;
      @include border-properties;
      @include default-border-color-of-widget;
      @include widget-font-size;

      &:focus {
        @include active-border-color-of-widget;
      }

      &::placeholder {
        @include place-holder-styles;
        display: flex;
        align-content: center;
      }
    }
    &.invalid {
      > input {
        @include error-border-color-of-widget;
        &:focus {
          @include active-border-color-of-widget;
        }
      }
    }
  }
}
