@import "../../sass/fontSizeUtility";
@import "../../sass/colorPalette";

.errorMessageContainer{
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    gap:.5rem;
    padding: 2rem;
   @include font_size(h01);
    .careTeamerrorMessage{
        color: #888;


    }
}