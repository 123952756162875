@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/buttonModules";
.vitalReading {
  @include layout-padding-property();
  padding-bottom: 1rem;
  .title {
    @include page-title-styles();
  }
  .advanceSearchBox {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
    .box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      //   align-items: baseline;
      .head {
        @include font_size(b03);
      }
      > input {
        width: 100%;
        height: 2.5rem;
        padding: 0.7rem;
        border: 1px solid #000;
      }

      .checkboxwrap {
        display: flex;
        align-items: center;
        gap: 1rem;
        .checkboxContainer {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          > p {
            @include font_size(b04);
          }
          > input {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
      }
      .select2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // flex-direction: column;
        gap: 1rem;
        > span {
          > input {
            width: 100%;
            height: 2.5rem;
            padding: 0.7rem;
            border: 1px solid #000;
          }
        }
      }
    }
  }
  .buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
    > button {
      @include font_size(b02);
      padding: 0.5rem 1rem;
      color: #fff;
      background-color: $primary_color-1;
      border: none;
      border-radius: 4px;
      &:hover {
        background-color: $primary_color-light-1;
        cursor: pointer;
      }
    }
  }
  .servicesBody {
    .servicesButtons {
      display: flex;
      justify-content: end;
      gap: 1rem;
      margin: 2rem 0;
      > button {
        @include font_size(b03);
        padding: 0.5rem 1rem;
        background-color: $primary_color-1;
        color: #fff;
        border: none;
        border-radius: 4px;
        &:hover {
          background-color: $primary_color-light-1;
          cursor: pointer;
        }
      }
    }
    .serviceLogTable {
      .note {
        @include font_size(b04);
        font-weight: 600;
        margin-bottom: 2rem;
        color: #ff7801;
      }

      .tableElement {
        border-right: 1px solid #000;
        padding: 1rem 0 1rem 1rem;
        @include font_size(b04);
      }
      .grid1 {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        border: 1px solid #000;
        &.header {
          font-weight: 700;
        }
      }
      .tableBodyElement {
        overflow: scroll;
        border-right: 1px solid #000;
        padding: 1rem 0 1rem 1rem;
        @include font_size(b04);
      }
      .tableBodyElementAction {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0.5rem;
        > input {
          width: 21px;
          height: 21px;
        }
        .icon {
          cursor: pointer;
        }
      }

      .totalcpmd {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        @include font_size(b04);
        font-weight: 600;
        .total {
          padding: 0.5rem;
          text-align: end;
          border-right: 1px solid #000;
        }
        .totalTime {
          padding: 0.5rem;
          text-align: start;
          border-right: 1px solid #000;
        }
      }
    }
  }
}
