@import "../../../sass/fontSizeUtility";
@import "../../../sass/generalUtility";
@import "../../../sass/colorPalette";
@import "../../../sass/utility.scss";

.addManualData {
  .Manualgrid {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    .txtHead {
      text-align: center;
      @include font_size(b03);
      font-weight: 600;
      color: #222;
      display: flex;
      text-align: center;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }
    .gridBoX {
      .manualElements {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        > input {
          height: 3rem;
          padding: 1rem;
        }
        .manualElementsLabel {
          @include font_size(b04);
          //   font-weight: 500;
        }
      }
    }
  }
  .addManualData-button {
    width: 100%;
    margin: 1rem;
    display: flex;
    justify-content: end;
    .btn-manualData {
    }
  }
}
