@import "../../../sass/generalUtility";
@import "../../../sass/colorPalette";
@import "../../../sass/fontSizeUtility";
@mixin title-props {
  @include font_size(b05);
  margin: 0.3rem 0 0.2rem;
  color: #888888;
  font-weight: 500;
}
.timerNotification {
  .timerContentPopUp {
    position: relative;

    background: #fff;
    .content-text {
      @include font_size(h01);
      font-weight: 600;
      color: $primary_color-1;
      display: flex;
      margin-bottom: 1rem;
      gap: 1rem;
      > span {
        width: 40%;
        .selectsearch {
          width: 100%;
          // height: 2rem;
          border: 2px solid $primary_color-1;
          border-radius: 4px;
          .dropFlex {
            border-radius: 4px;
            .spanElementdiv {
              height: 2.8rem;
              border-radius: 4px;
              .selected {
                border-radius: 4px;
                // background-color: #fff;
                // padding: 0.5rem;
                // z-index: 0;
                // height: 100%;
                // width: 95%;
                // overflow: hidden;
                // @include font_size(b04);
                // border: none;
                // text-decoration: none;
                // outline: none;
              }

              .dropdown {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: #fff;
                z-index: 20;

                .options {
                  outline: 2px solid $primary_color-1;
                  border-bottom-right-radius: 4px;
                  border-bottom-left-radius: 4px;

                  li {
                    border-bottom: 1px solid #000;

                    &:hover {
                      background-color: #f0f0f0;
                    }
                  }
                  :last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  > p {
    @include font_size(h02);
    font-weight: 600;
    color: $primary_color-1;
  }
  .popBtn {
    width: 100%;
    text-align: end;
    margin-top: 1rem;
    > button {
      // width: 10rem;
      padding: 8px 16px;
      border: none;
      background-color: $primary_color-1;
      border: 1px solid $primary_color-1;
      color: #fff;
      @include font_size(b04);
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-right: 1rem;
    }
    :nth-child(2) {
      color: $primary_color-1;
      background-color: #fff;
    }
  }
}

.callPlanWrap {
  max-width: 100%;
  //   margin: 0 10rem;
  @include layout-padding-property();
  .ccmHeading {
    width: 100%;
    border-bottom: 1.5px solid #888888;
    padding-bottom: 2px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    @include page-title-styles();
    // padding-bottom: 1rem;
    .callingPagetitle {
      line-height: 100%;
    }
    .divbtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      .downloadBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        text-decoration: none;
        height: 4rem;
        padding: 0.5vw 1.5vw;
        @include font_size(b03);
        outline: none;
        border: 1px solid $primary_color-1;
        background: $primary_color-1;
        color: #fff;
        border-radius: 0.3vw;
        transition: all 150ms ease-in-out;
        &:hover {
          opacity: 0.8;
        }
      }
      .download-button {
        a,
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          text-decoration: none;
          height: 4rem;
          padding: 0.5vw 1.5vw;
          @include font_size(b03);
          outline: none;
          border: 1px solid $primary_color-1;
          background: $primary_color-1;
          color: #fff;
          border-radius: 0.3vw;
          transition: all 150ms ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .headerbtns {
        border: 1px solid #7a5dda;
        border-radius: 4px;
        padding: 0.75rem 1.5rem;
        color: $primary_color-1;
        display: flex;
        // align-items: center;
        // justify-content: center;
        gap: 2px;
        font-weight: 600;
      }
    }
  }
  .detailsSection {
    .detailsSubComponent {
      background-color: #eeeeee;
      margin-top: 0.5em;
      border: 1px solid #888888;
      border-radius: 8px;

      padding: 1rem;
      display: grid;
      grid-template-columns: minmax(140px, 3fr) 16fr;
      .detailsSubComponentLeft {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-row-gap: 0.5rem;

        .element {
          @include font_size(b02);
          font-weight: 600;
          color: #505050;
          // padding: 1.5rem 0;
          .subelement {
            @include title-props();
          }
        }
      }
      .detailsSubComponentRight {
        .title {
          @include title-props();
        }
        .diagnosisContainer {
          height: 8rem;
          @include font_size(b03);
          overflow-y: scroll;
          display: grid;
          grid-column-gap: 1rem;
          grid-auto-flow: row;
          grid-template-rows: repeat(3, 2.5rem);
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: 2rem;
          .diagnosis {
            .icd-code {
              font-weight: 500;
            }
            height: 1lh;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .deatailsHead {
      margin-top: 2rem;
      display: flex;
      gap: 5rem;
      .patientname {
        font-weight: 500;
        @include font_size(h01);
      }
      .detailsbtns {
        display: flex;
        gap: 1rem;
        .btnWrap {
          width: calc(1.5rem + 0.8vw);
          height: auto;
          aspect-ratio: 1;
          display: grid;
          place-items: center;
          background-color: $primary_color-1;
          outline: none;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          transition: all 350ms ease-in-out;
          svg.icon {
            width: calc(1.5rem + 0.5vw);
            height: auto;
            aspect-ratio: 1;
            path {
              fill: #fff;
            }
          }
          &:hover {
            opacity: 0.85;
          }
        }
      }
    }
  }
  .Testing {
    width: 100%;
    min-height: 30vh;
  }
}
