@import "../../sass/generalUtility";
@import "../../sass/buttonModules";
@import "../../sass/widgetUtility";
@import "../../sass/fontSizeUtility";

.rw-input {
  @include font_size(b04);
}
.addServices {
  .popTitle {
    color: #222;
    font-weight: 500;
    @include font_size(b02);
    text-align: center;
    margin-bottom: 4rem;
  }
  .addContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    margin-bottom: 5rem;
    .notes {
      @include font_size(b06);
      // padding-top: 0.5rem;
    }
    .popElement {
      margin-bottom: 2rem;

      .viewElement {
        width: 100%;
        padding: 0.5rem;
      }

      .radio {
        display: flex;
        gap: 2rem;
        align-items: center;
        .radio-align {
          display: flex;
          gap: 0.5rem;
          justify-content: center;
          align-items: center;
          @include font_size(b04);
        }
      }

      .popLabel {
        color: #222;
        font-weight: 400;
        @include font_size(b04);
        margin-bottom: 0.5rem;
      }
      .field {
        height: 2.5rem;
        width: 100%;
        @include font_size(b04);
      }
    }
  }
  .popBtn {
    width: 100%;
    text-align: end;
    margin-top: 1rem;
    > button {
      width: 10rem;
      padding: 8px 16px;
      border: none;
      background-color: $primary_color-1;
      border: 1px solid $primary_color-1;
      color: #fff;
      @include font_size(b04);
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-right: 1rem;
    }
    :nth-child(2) {
      color: $primary_color-1;
      background-color: #fff;
    }
  }
}
