@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/buttonModules";
@import "../sass/utility.scss";
.performance {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .viewPerformance {
    height: 100vh;
    overflow-y: scroll;
    width: 80vw;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    // margin-top: 5rem;
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    .closeIcon {
      position: absolute;
      cursor: pointer;

      top: 1%;
      right: 1%;
      &:hover {
        color: #555;
      }
    }
    .headtitle {
      width: 100%;
      @include font_size(b01);
      text-align: center;
      font-weight: 600;
      color: #222;
      margin-bottom: 4rem;
    }
    .performanceGrid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1rem;
      .gnrltxt {
        @include font_size(b05);
        text-align: center;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
      .blue {
        color: #7a5dda;
        margin-bottom: 1rem;
      }
      .DashCompo {
        border: 1px solid #888;
        border-radius: 4px;
        background: #eee;
        margin-bottom: 1rem;
        .compoTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #888;
          .title-icon {
            display: flex;
            gap: 1rem;
            align-items: center;
            // justify-content: left;
            .title {
              color: #505050;
              @include font_size(b04);
              font-weight: 600;
            }
          }
        }
        .compoBody {
          .cla {
            height: 160px;
          }
          .gridfor4 {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;
            .elem {
              display: grid;
              grid-template-columns: 2fr 1fr;
              // justify-content: center;
              // align-items: center;
              box-shadow: 0px 2px 6px 2px #dfd6d6;
              border-radius: 8px;

              .iconbtn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1rem;
                gap: 1rem;

                .Txt1 {
                  @include font_size(b05);
                  font-weight: 600;
                  color: #222;
                }
              }
              .num1 {
                @include font_size(h01);
                font-weight: 600;
                color: #222;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .weeklyIcon {
              padding: 0.5rem;
              border-radius: 50%;
              width: 3rem;
              height: 3rem;
            }
            .yellowcall {
              .iconbtn {
                background: rgba(234, 202, 31, 0.25);
                border-right: 0.5px solid #eaca1f;
                .weeklyIcon {
                  background: #eaca1f40;
                }
              }
              border: 0.5px solid #eaca1f;
            }
            .redcall {
              .iconbtn {
                background: rgba(255, 64, 48, 0.25);
                border-right: 0.5px solid #ff4030;
                .weeklyIcon {
                  background: #ff403040;
                }
              }
              border: 0.5px solid #ff4030;
            }
            .greencall {
              .iconbtn {
                border-right: 0.5px solid #10ac50;
                background: rgba(16, 172, 80, 0.25);
                .weeklyIcon {
                  background: #10ac5040;
                }
              }
              border: 0.5px solid #10ac50;
            }
            .darkcall {
              .iconbtn {
                border-right: 0.5px solid #888;
                background: rgba(136, 136, 136, 0.25);

                .weeklyIcon {
                  background: #88888840;
                }
                .Txt1 {
                  color: #888;
                }
              }
              border: 0.5px solid #888;
              .num1 {
                color: #888;
              }
            }
          }
          .contributionElem {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            .elemBox {
              display: flex;
              padding: 9px 8px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 0.5px solid #888;
              background: #fff;
              box-shadow: 0px 2px 6px 2px #dfd6d6;
              .contridiv {
                display: flex;
                // grid-template-columns: 1fr 1fr;
                gap: 1rem;
                .contribox {
                  border-radius: 8px;
                  border: 0.5px solid #888;
                  background: #fff;
                  height: 200px;
                  box-shadow: 0px 2px 6px 2px #dfd6d6;
                  display: flex;
                  flex-direction: column;
                  padding: 1rem;
                  align-items: start;
                  gap: 8px;
                  // flex: 1 0 0;
                  .contiBoxtxt {
                    display: flex;
                    padding: 1.5rem 1rem;
                    width: 100%;
                    max-height: 4rem;
                    justify-content: center;
                    align-items: center;
                    @include font_size(b05);
                    font-weight: 500;
                    text-align: center;
                    gap: 0.5rem;
                    color: #222222;
                    border-radius: 4px;
                    .padd {
                      padding: 0.7rem;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                  .contiseparation {
                    display: flex;
                    // grid-template-columns: 1fr 1fr;
                    // gap: 1rem;

                    width: 100%;
                    > :first-child {
                      border-right: 1px solid #888;
                    }
                  }
                  .num {
                    @include font_size(h06);
                    width: 100%;
                    text-align: center;
                    margin-bottom: 1rem;
                  }
                  .txt4 {
                    @include font_size(b06);
                    text-align: center;
                    width: 100%;
                    margin-bottom: 1rem;
                  }

                  .greybox {
                    background: #eee;
                  }
                  .ligthbox {
                    background: rgba(122, 93, 218, 0.25);
                  }
                }
              }
              .gridChange {
                display: grid;
                grid-template-columns: 1fr 2fr;
              }
              .grid3col {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
              }
              .txtHead {
                @include font_size(b02);
                color: #888888;
                font-weight: 600;
                text-align: center;
                margin-bottom: 3rem;
                margin-top: 2rem;
              }
              .bigNum {
                @include font_size(h06);
                color: #505050;
                font-weight: 600;
                text-align: center;
              }
              .smallertxt {
                color: #888;
                @include font_size(b06);
                text-align: center;
                width: 100%;
              }
              .smaltxtc {
                color: #888;
                font-weight: 600;
                @include font_size(b05);
              }
            }
          }
          .grid552 {
            display: grid;
            grid-template-columns: 4.5fr 4.5fr 3fr;
          }
          .calender {
            display: flex;
            @include font_size(b04);
            padding: 0.5rem;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #888;
            background: #fff;
            border-radius: 4px;

            margin-bottom: 1rem;
          }
          .calBody {
            box-shadow: 0px 0px 6px 2px #dfd6d6;

            border-radius: 4px;
            border: 1px solid #888;
            background: #fff;
          }
          .past {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            height: 160px;
          }
          .colourless {
            // background-color: #fff;
          }
          .gnrrl {
            @include font_size(h01);
            font-weight: 600;
            color: #888;
            text-align: center;
          }
          .pastShedule {
            display: flex;
            flex-direction: column;
            box-shadow: 0px 2px 6px 2px #dfd6d6;
            border-radius: 8px;
            gap: 1rem;
            padding: 1rem;
            background-color: #fff;
          }
          .pastShedule:nth-child(1) {
            border: 0.5px solid #ff4030;
          }
          .pastShedule:nth-child(2) {
            border: 0.5px solid #10ac50;
          }
          .pastShedule:nth-child(3) {
            border: 0.5px solid #eaca1f;
          }
          .pastShedule.clrless {
            border: none;
          }
          .sheduleDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 4px;
            // padding: 1.5rem 1rem;
            background-color: #eee;
            color: #888;
          }
          .red {
            background: rgba(255, 64, 48, 0.2);
            color: #ff4030;
          }
          .green {
            background: rgba(16, 172, 80, 0.25);
            color: #10ac50;
          }
          .yellow {
            background: rgba(234, 202, 31, 0.25);
            color: #eaca1f;
          }
          .simTxt {
            @include font_size(b06);
            font-weight: 600;
            text-align: center;
          }

          .pastShedulebtn {
            display: flex;
            justify-content: center;
            gap: 1rem;
            align-items: center;
            .actionbtn {
              border-radius: 50%;
              padding: 0.3rem;
              @include font_size(b04);
              font-weight: 600;
            }
            .clr1 {
              color: #7a5dda;
              border: 1px solid #7a5dda;
            }
            .clr2 {
              color: #a99c58;
              border: 1px solid #a99c58;
            }
          }
        }
      }
      .boxes {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 1rem;
        .box {
          display: flex;
          padding: 14px 8px;
          flex: 1 0 0;
          align-self: stretch;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 0.5px solid #888;
          background: #fff;
          box-shadow: 0px 2px 6px 2px #dfd6d6;
          .boldnum {
            @include font_size(h06);
            font-weight: 600;
            letter-spacing: -0.72px;
          }
          .txt {
            @include font_size(b04);
          }
        }
      }
      .grid7 {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        border: 1px solid #888;
        border-radius: 4px;
        background: #fff;
        .gridHead {
          border-right: 1px solid #888;
          display: flex;
          justify-content: center;
          align-items: center;
          @include font_size(b05);
          color: #222;
          font-weight: 600;
          text-align: center;
        }
        .gridHead:last-child() {
          border-right: none;
        }

        .gridcnt {
          text-align: center;
          border-right: 1px solid #888;
          border-bottom: 1px solid #888;
          padding-top: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-bottom: 1rem;
          .day {
            @include font_size(b04);
            color: #222;
            font-weight: 500;
            margin-bottom: 1rem;
          }
          .date {
            @include font_size(h01);
            font-weight: 500;
            color: #222;
            padding: 0.5rem;
          }
          .currentDate {
            background-color: #7a5dda;
            border-radius: 50%;
            color: #fff;
            padding: 0.5rem;
          }
        }
        .gridcnt:last-child() {
          border-right: none;
        }
        .gridbtn {
          border-right: 1px solid #888;
          display: flex;
          //   gap: 0.5rem;
          width: 100%;
          overflow-x: hidden;
          justify-content: space-between;
          align-items: center;
          padding: 0.4rem;
          .actionbtn {
            border-radius: 50%;
            padding: 0.3rem;
            @include font_size(b04);
            font-weight: 600;
          }
          .clr1 {
            color: #7a5dda;
            border: 1px solid #7a5dda;
          }
          .clr2 {
            color: #a99c58;
            border: 1px solid #a99c58;
          }
          .clr3 {
            color: #ff4030;
            border: 1px solid #ff4030;
          }
          .gridbtn:last-child() {
            border-right: none;
          }
        }
      }
      .rigthtext {
        display: flex;
        justify-content: end;
        gap: 1rem;
        margin-bottom: 1rem;
        .cir {
          height: 1.5rem;
          width: 1.5rem;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
      }
    }
    // .popchild {
    //   padding-top: 1rem;
    // }
  }
}
