@import "../../../sass/fontSizeUtility";
@import "../../../sass/generalUtility";

.callFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-block: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  // border-top: 1px solid #000;
  z-index: 100;
  .callFooterContainer {
    border: 2px solid #888888;
    display: flex;
    padding: 1rem 1rem;
    padding-top: 1.5rem;
    width: fit-content;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .timeDisplay {
      height: 4rem;
      padding-right: 1rem;
      display: flex;
      border-right: 1px solid #888888;
      .element {
        position: relative;
        @include font_size(b02);
        font-weight: 600;
        color: #505050;
        padding: 2rem 0;
        .subelement {
          @include font_size(b05);
          position: absolute;
          color: #888888;
          font-weight: 500;
          left: 0;
          top: 0%;
        }
      }
    }
    .opencompount {
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      .opencompountBtn {
        border: none;
        background: #cccccc;
        border-radius: 100px;
        padding: 10px 20px;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .callbtnsWrap {
      border-right: 1px solid #888888;
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      height: 4rem;

      .callBtns {
        cursor: pointer;
        width: 4rem;
        height: 4rem;
        background-color: #cccccc;
        border-radius: 50%;
        outline: none;
        border: none;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
          path {
            fill: #222222;
          }
        }
        &:not(.disabled):hover {
        }
        &.mic,
        &.video {
          @include mute_properties;
        }
        &.endCall {
          background-color: #f00;
          svg {
            path {
              fill: #fff;
            }
          }
          &.disabled {
            background-color: #0f0;
          }
        }
      }
    }
  }
}
