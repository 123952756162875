@import "../../../sass/fontSizeUtility";
@import "../../../sass/buttonModules";
@import "../../../sass/colorPalette";
.popUpDelete {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .popupContent {
    background-color: #fff;
    padding: 30px;
    @include font_size(b01);
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;

    p {
      margin-bottom: 30px;
    }

    .popupActions {
      display: flex;
      justify-content: center;
      gap: 20px;

      button {
        padding: 8px 16px;
        border: none;
        background-color: $primary_color-1;
        color: #fff;
        @include font_size(b01);

        border-radius: 3px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: $primary_color-dark-1;
        }
      }
    }
  }
}

.iconContainer {
  display: flex;
  gap: 10px;

  .btncontainer {
    background-color: #7a5dda;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: #fff;
    // width: 25px;
    // aspect-ratio: 1;
    border-radius: 10%;
    cursor: pointer;
    transition: background-color 350ms ease-in-out;
    &:last-child {
      margin-right: 1rem;
    }
    &.btnContainer2 {
      background-color: #f0f0f0;
      color: #444;
      margin-right: 0px;
    }
    &:hover {
      background-color: #554199;
      //   color: #7a5dda;
      &.btnContainer2 {
        background-color: #ccc;
      }
    }
  }
}
