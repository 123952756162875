.maintainance {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c167a;
  img {
    display: block;
    height: 100%;
    width: auto;
    object-fit: cover;
  }
}
