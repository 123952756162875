@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  font-size: 62.5%;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter";
}
textarea{
  font-family: "Inter";
  padding:.5rem 0 0 .5rem;
  
}
input{
  font-family: "Inter";
  
}
select{
  font-family: "Inter";

}