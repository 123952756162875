@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";

.topBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  z-index: 11;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $primary_color-1;
  // background-color: #f00;
  .cont {
    // height: 100%;
    @include topBar-height-property();
    display: flex;
    flex-direction: column;
    justify-content: center;
    .topBarContainer {
      display: flex;
      @include layout-padding-property();
      align-items: center;
      height: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      justify-content: space-between;
      flex-grow: 1;
      .topBarContainer-left {
        display: flex;
        align-items: center;
        // height: 100%;
        .menu {
          cursor: pointer;
        }
        .logo {
          width: auto;
          margin-left: 1vw;
        }
      }
      .topBarContainer-right {
        display: flex;
        align-items: center;
        .userDescContainer {
          display: flex;
          align-items: center;
          .descText {
            padding-left: 0.8vw;
            color: #fff;
            .title {
              @include font_size("b03");
              padding-bottom: 0.2vw;
            }
            .designation {
              @include font_size("b06");
              text-transform: capitalize;
            }
          }
        }
        .logoutBox {
          display: flex;
          align-items: center;
          padding-left: 2.8vw;
          padding-right: 2.2vw;
          cursor: pointer;
          .text {
            color: #fff;
            transition: all 350ms ease-in-out;
            @include font_size("b02");
          }
          svg {
            margin-left: 1vw;
            path {
              transition: all 350ms ease-in-out;
            }
          }
          &:hover {
            .text {
              color: #ccc;
            }
            svg {
              path {
                fill: #ccc;
              }
            }
          }
        }

        .iconContainer {
          display: flex;
          align-items: center;
          img:not(:first-child) {
            aspect-ratio: 1;
            margin-left: 1vw;
          }
        }
      }
    }
  }
}
