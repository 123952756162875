@import "../sass/fontSizeUtility";

.stopwatchContainer {
  display: flex;
  position: relative;
  width: 100%;
  // justify-content: center;
  align-items: center;
  gap: 1rem;

  .timerContainer {
    width: 50%;
    .title {
      @include font_size(b04);
      color: #888888;
    }
    .timer {
      @include font_size(h03);
      color: #505050;
      font-weight: 600;
    }
  }

  .timerbuttons {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;

    > button {
      cursor: pointer;
      border-radius: 24px;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      transition: all 200ms ease-in-out;
      &:nth-child(1) {
        background-color: #007fff;
      }
      &:nth-child(2) {
        background-color: #00cccc;
      }
      &:nth-child(3) {
        background-color: #ff0800;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .dialogBox {
    position: absolute;
    background-color: #fff;
    z-index: 10;
    left: 0;
    top: 130%;
  }
}
