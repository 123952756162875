@import "../../sass/fontSizeUtility";
.programContainer {
  position: relative;
  padding-top: 6rem;
  .addButton {
    position: absolute;
    top: 1rem;
    right: 0;
    @include font_size(b03);
    font-weight: 600;
    background-color: #7a5dda;
    color: #fff;
    border-radius: 4px;
    padding: 1rem;
    border: none;
  }
  > table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #888888;
    border-radius: 4px;

    //   overflow: hidden;
  }
  td {
    border: 1px solid #888888;
    padding: 1rem;
    @include font_size(b03);
    .iconWraptd {
      // height: 100%;
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      // border-collapse: collapse;
      // box-sizing: content-box;
    }
    .icon {
      height: 100%;
      // padding: 0.3rem;
      &:hover {
        background-color: #eeeeee;
        border-radius: 50%;
      }
    }
  }

  th {
    font-weight: 500;
    border: 1px solid #888888;
    text-align: left;
    @include font_size(b03);
    background-color: #eeeeee;
    padding: 8px;
  }
}
