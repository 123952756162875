@import "../sass/generalUtility";
@import "../sass/colorPalette";
.sideBarSectionContainer {
  position: relative;

  @include layout-padding-property();
  .titleContainer {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    > .title {
      display: flex;
      width: 100%;
      gap: 1vw;

      > svg.icon {
        // margin-right: 1vw;
        // width: 100%;
        align-self: center;
      }
    }
    > svg {
      transition: all 350ms ease-in-out;
    }
  }
  &:hover {
    background-color: #ddd;
  }

  &.currentlySelected {
    background-color: $primary_color-1;
    > .titleContainer {
      > .title {
        color: #fff;
        > svg.icon {
          path {
            stroke: #fff;
          }
        }
      }

      > svg {
        transform: rotateZ(90deg);
        path {
          fill: #fff;
        }
      }
    }
  }

  .sideBarSectionContainer_children {
    background-color: #fff;
    color: #000;
    content: "";
    position: absolute;
    top: 0;
    left: calc(100% + 0.5rem);
    border-radius: 0.3vw;
    overflow: hidden;
    &.hidden {
      display: none;
    }
  }
}
