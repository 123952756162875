@import "../sass/fontSizeUtility";
@import "../sass/widgetUtility";
@import "../sass/sizes";
@import "../sass/colorPalette";

.datePickerFieldContainer {
  width: 100%;

  > .datePickerField {
    width: 100%;

    > .title {
      @include widgets-title;
    }

    .rw-date-picker {
      .rw-widget-picker {
        box-shadow: none;
        min-height: initial;
        @include height-of-widget;
        // @include widget-padding;
        @include border-properties;
        @include default-border-color-of-widget;
        @include widget-font-size;

        .rw-widget-input {
          @include widget-font-size;

          // color: #000;
        }
      }
      button {
        border: none;
        svg {
          margin-right: 0.7rem;
          height: 60%;
        }
      }
      &.rw-state-focus {
        .rw-widget-picker {
          border-color: #4b4ded;
        }
      }
    }
    &.invalid {
      .rw-date-picker {
        .rw-widget-picker {
          border-color: #ee6161;
        }
        &.rw-state-focus {
          > .rw-widget-picker {
            border-color: #4b4ded;
          }
        }
      }
    }
  }
}
