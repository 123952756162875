@import "../sass/fontSizeUtility";
@import "../sass/colorPalette";


.sidemenu{
    width:15%;
    height: 90%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    border-radius: 4px;
    overflow-y: scroll;
    padding-bottom: 2rem;
    
    .element{
        // padding: 1rem 1.5rem;
        @include font_size(b02);
        align-items: center;
        border: none;
        font-weight: 500;
        padding: 1rem 1rem;
        color: #222222;
        &:hover{
            background-color: $primary_color-1;
            border-radius: 4px;
            color: #fff;
            
        }

    }
    .activeSideBar{
        background-color: $primary_color-1;
        border-radius: 4px;
            color: #fff;

    }
    
}