@import "../sass/colorPalette";
@import "../sass/buttonModules";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
.transferPatients {
  @include layout-padding-property();
  @include topbar-margin-property();
  .transferPatientstitle {
    @include page-title-styles;
    margin-bottom: 2rem;
  }
  .nav {
    display: flex;
    border: 1px solid #000;
    width: fit-content;
    border-radius: 4px;
    transition: all 0.1s ease;
    margin-bottom: 2rem;

    .navHeader {
      @include font_size(b03);
      font-weight: 400;
      padding: 1rem;
    }
    .navHeaderSelected {
      color: #fff;
      background-color: $primary_color-1;
      border: none;
      //   border-radius: 0 4px 4px 0;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: start;
    .patientTransferBody {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;
      row-gap: 3rem;
      column-gap: 2rem;
      .filter1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
      .box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        //   align-items: baseline;
        .head {
          @include font_size(b03);
        }
        > input {
          width: 100%;
          height: 2.5rem;
          padding: 0.7rem;
          border: 1px solid #000;
        }
      }
      .transfer-button {
        grid-column: span 2;
        width: 100%;
        margin: 1rem;
        display: flex;
        gap: 1rem;
        justify-content: end;
        .btn-manualData {
        }
      }
      .patientTransferSec {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .transferBtns {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 0 2rem;
        }
      }
    }
  }
}
