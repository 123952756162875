@import "../../../sass/fontSizeUtility";
@import "../../../sass/buttonModules";
@import "../../../sass/colorPalette";

.detailsContainer {
  width: 80%;
  margin: 0 auto;
  padding: 3rem 0;
  input[type="date"]:in-range::-webkit-datetime-edit-year-field,
  input[type="date"]:in-range::-webkit-datetime-edit-month-field,
  input[type="date"]:in-range::-webkit-datetime-edit-day-field,
  input[type="date"]:in-range::-webkit-datetime-edit-text {
    color: transparent;
  }
  height: 60vh;
  overflow-y: scroll;

  .displayTitleGnrl {
    width: 100%;
    background-color: #eee;
    padding: 0.5rem;
    text-align: center;
    font-weight: 500;
    @include font_size(b04);
  }
  .display-sec-Gnrl {
    display: flex;
    gap: 8rem;
    position: relative;

    .display-content-Gnrl {
      width: 50%;
      // background-color: #f00;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 0;
      > select {
        .default {
          display: none;
        }
      }
      .multiselectCont {
        .rw-popup-container {
          font-family: "Inter";
          @include font_size(b04);
        }
        .rw-multiselect-tag-label {
          font-family: "Inter";
          @include font_size(b04);
        }
      }
      .doubleSideElement {
        display: flex;
        gap: 3px;
        > div {
          width: 100%;
        }
        select {
          .default {
            display: none;
          }
        }

        :last-child {
          > label {
            opacity: 0;
          }
        }
        // grid-template-columns: 1fr 1fr;
      }
      .display-element-Gnrl {
        width: 100%;
        @include font_size(b04);
      }
      .displayDescription-color {
        > textarea {
          padding: 1rem;
          background-color: #eee;
          height: 5rem;
        }
      }
      .displayDescription {
        > textarea {
          // background-color: #eee;
          padding: 1rem;
          height: 8rem;
        }
      }
    }
    .display-content-fullwidth {
      width: 100%;
      height: 100%;
      .displayDescription-color {
        > textarea {
          background-color: #eee;
          height: 20rem;
        }
      }
    }
  }
  .display-sec-Gnrl-three {
    gap: 4rem;
  }
  .display-content-life {
    padding: 2rem 0;
    width: 100%;
    > .display-element-Gnrl {
      width: 100%;
      margin: 1rem 0;
      @include font_size(b04);
    }
    .display-life-div {
      margin-top: 1rem;
      display: flex;
      // flex-direction: column;
      .life-head {
        width: 30%;
        @include font_size(b04);
      }
      .life-sub-element {
        width: 70%;
        .life-textarea {
          > textarea {
            // background-color: #eee;
            height: 5rem;
            width: 100%;
          }
        }

        .life-subhead {
          margin-bottom: 1rem;
          @include font_size(b04);
          display: flex;
          gap: 1rem;
          // justify-content: center;
          align-items: center;
          // background-color: #f;
          .life-span-element {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
