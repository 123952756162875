@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
.weekCont {
  display: grid;
  grid-template-columns: 1fr 7fr;
  .daybody {
    border-bottom: 1px solid #888;

    .day {
      @include font_size(b01);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      text-align: center;
      grid-column: span 1;
      text-transform: uppercase;
      //   border-bottom: 1px solid #888;
      //   border-right: 1px solid #888;
    }
  }
  .calendarBody {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // border: 1px solid #888;
    margin-top: 2rem;
    .day {
      @include font_size(b03);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      grid-column: span 1;
      //   border-bottom: 1px solid #888;
      border-right: 1px solid #888;
      padding: 1rem;
    }
    .weekComponent {
      display: flex;
      @include font_size(b04);
      grid-column: span 1;
      justify-content: center;
      align-items: center;
      height: 4rem;
      //   border-bottom: 1px solid #888;
      border-right: 1px solid #888;
    }
    .present {
      background-color: #fcf8e3;
    }
  }
}
