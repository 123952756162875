@import "../sass/generalUtility";
@import "../sass/colorPalette";
.sideBarElements {
  @include layout-padding-property();
  &:hover {
    background-color: #ddd;
  }
  .title {
    display: flex;
    > svg.icon {
      margin-right: 1vw;
    }
  }
  &.currentlySelected {
    background-color: $primary_color-1;
    .title {
      color: #fff;
      > svg.icon {
        path {
          fill: #fff;
        }
      }
    }
  }
}
