@import "../sass/generalUtility";
@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";

.eventViewContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .eventViewContent {
    position: relative;
    // width: 50vh;
    top: 10%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    // margin-top: calc($topBarHeight);

    padding: 4rem;
    background: #fff;
    .closeIcon {
      position: absolute;
      cursor: pointer;
      top: 1%;
      right: 2%;
      &:hover {
        color: #555;
      }
    }
    .popchild {
      padding-top: 1rem;
      .title {
        text-align: center;
        @include font_size(h01);
        margin-bottom: 4rem;
      }
      .childgrid {
        display: flex;
        gap: 5rem;
        .element {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          .displayElement {
            @include font_size(b05);
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            .head {
              font-weight: 600;
            }
          }
        }
      }
      .eventViewButtons {
        margin-top: 2rem;
        // margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        > button {
          border: none;
          @include font_size(b04);
          padding: 0.5rem 1rem;
          outline: none;
          transition: background-color 0.3s;
          cursor: pointer;
          background-color: $primary_color-1;
          color: #fff;
          &:hover {
            background-color: $primary_color-dark-1;
          }
        }

        > button:last-child {
          background-color: #ff4c4c;
          &:hover {
            background-color: #ff3232;
          }
        }
      }
    }
  }
}
