@import "../sass/fontSizeUtility";

.selectsearch {
  width: 100%;
  // height: 2rem;
  border: 1px solid #000;
  .dropFlex {
    .spanElementdiv {
      position: relative;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.5rem;
      padding-right: 0.5rem;
      width: 100%;
      border: none;
      text-decoration: none;
      outline: none;
      // input {
      //   // height: 2rem;
      // }
      .selected {
        background-color: #fff;
        padding: 0.5rem;
        z-index: 0;
        height: 100%;
        width: 95%;
        overflow: hidden;
        @include font_size(b04);
        border: none;
        text-decoration: none;
        outline: none;
      }

      .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 20;

        .options {
          list-style-type: none;
          max-height: 15rem;
          // height: 10rem;
          // height: 15rem;
          overflow-y: scroll;
          // z-index: 9999;
          outline: 1px solid #000;

          li {
            // z-index: 35;
            padding: 10px;
            @include font_size(b04);
            cursor: pointer;
            border-bottom: 1px solid #000;

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
    }
  }
}
.disabledSelector {
  cursor: not-allowed;
  border: 1px solid #ddd;

  .dropFlex {
    .spanElementdiv {
      background-color: #ddd;
      .selected {
        background-color: #ddd;
      }
    }
  }
}
