@import "fontSizeUtility";
@import "colorPalette";
@mixin login-button-styles {
  width: 100%;
  height: calc(1.5rem + 2vw);
  background-color: $primary_color-1;
  color: #fff;
  border: 2px solid $primary_color-1;
  outline: none;
  border-radius: 0.5vw;
  cursor: pointer;
  @include font_size("b02");
  transition: all 350ms ease-in-out;
  &:hover {
    border: 2px solid $primary_color-dark-1;
    background-color: $primary_color-light-3;
    color: $primary_color-dark-2;
  }
  &:disabled {
    border: 2px solid $primary_color-1;
    background-color: $primary_color-1;
    color: #fff;
    filter: opacity(0.8);
  }
}
