@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/buttonModules";
.dashBoard {
  @include layout-padding-property();

  .count {
    border-radius: 4px;
    border: 1px solid #888;
    background: #ebebeb;
    margin-bottom: 2rem;
    .countDiv {
      padding: 10px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .countItem {
        color: #888;
        @include font_size(b05);

        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.15px;
        > span {
          color: #505050;

          @include font_size(b03);
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.16px;
        }
      }
    }
  }

  .advanceSearchBox {
    border-radius: 4px;
    border: 1px solid #888;
    background: #ebebeb;
    display: flex;
    gap: 1.5rem;
    justify-content: end;
    margin-bottom: 1rem;
    padding: 1rem;
    .box {
      display: flex;
      gap: 1rem;
      align-items: center;
      .head {
        @include font_size(b03);
      }
      > input {
        width: 100%;
        height: 2.5rem;
        padding: 0.7rem;
        border: 1px solid #000;
      }

      .select2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // flex-direction: column;
        gap: 1rem;
        > span {
          > input {
            width: 100%;
            height: 2.5rem;
            padding: 0.7rem;
            border: 1px solid #000;
          }
        }
      }
    }
  }
  .section1 {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 2rem;
    margin-bottom: 1rem;
  }
  .splSection {
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
  }
  .ccmspl {
    display: grid;
    grid-template-columns: 1fr;
  }

  .DashCompo {
    border: 1px solid #888;
    border-radius: 4px;
    background: #eee;
    .compoTitle {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #888;
      .title-icon {
        display: flex;
        gap: 1rem;
        align-items: center;
        // justify-content: left;
        .title {
          color: #505050;
          @include font_size(b04);

          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.18px;
        }
      }
      .icons {
        border-radius: 4px;
        background-color: #7a5dda;
        display: flex;
        gap: 5px;
        @include font_size(b06);

        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        padding: 0.5rem;
        color: #fff;
      }
    }
    .compoBody {
      padding: 1rem 2rem;
      .inputtypeDisplay {
        display: flex;
        padding: 4px 8px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #888;
        background: #fff;
        @include font_size(b04);
        color: #222;
        font-weight: 400;
        margin-bottom: 1rem;
        > span {
          font-weight: 700;
        }
      }
      .textMessageBox {
        text-align: center;
        border-radius: 8px;
        padding: 12px 20px;
        margin-bottom: 1rem;
        border: 0.5px solid #888;
        background: var(
          --grey-linear,
          linear-gradient(180deg, #fff 0%, rgba(153, 150, 175, 0.1) 100%)
        );
        box-shadow: 0px 2px 6px 2px #dfd6d6;
      }
      .subTextBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 7rem;
      }
      .subTextBox .textMessageBox:nth-child(1) {
        background: linear-gradient(
          180deg,
          #fff 0%,
          rgba(255, 199, 107, 0.5) 100%
        );
        box-shadow: 0px 2px 6px 2px #dfd6d6;
      }
      .subTextBox .textMessageBox:nth-child(2) {
        background: linear-gradient(
          180deg,
          #fff 0%,
          rgba(22, 51, 205, 0.1) 100%
        );

        // box-shadow: 0px 2px 6px 2px #dfd6d6;
      }

      .left-rigth {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 1rem;
        .left {
          display: flex;
          padding: 8px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          gap: 1rem;
          border: 0.5px solid #888;
          background: var(
            --grey-linear,
            linear-gradient(180deg, #fff 0%, rgba(153, 150, 175, 0.1) 100%)
          );
          box-shadow: 0px 0px 6px 2px #dfd6d6;
          .txt {
            color: #222;
          }
          .num {
            @include font_size(h06);
            color: #222;
          }
        }
        .rigth {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          > select {
            padding: 0.5rem;
            border-radius: 4px;
          }
          .rigth-txt {
            @include font_size(b04);

            color: #505050;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.18px;
            > span {
              font-weight: 700;
            }
          }
        }
      }
      .rpmalert {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        .alert-box {
          display: flex;
          padding: 10px 16px;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          flex: 1 0 0;
          border-radius: 8px;
          border: 0.5px solid #888;
          background: var(
            --grey-linear,
            linear-gradient(180deg, #fff 0%, rgba(153, 150, 175, 0.1) 100%)
          );

          /* shadow */
          box-shadow: 0px 0px 6px 2px #dfd6d6;
          .action {
            color: #222;
            // text-align: center;
            @include font_size(b04);
            align-items: start;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.05px;
            margin-bottom: 1rem;
          }
          .timeDisplay {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 1rem;
            align-items: center;
            .time {
              padding: 1rem;
              color: #222;
              text-align: center;
              @include font_size(b05);

              .number {
                @include font_size(h05);
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.48px;
              }
            }
            .line {
              height: 100%;
              width: 1px;
              background-color: #888;
            }
          }
        }
      }
      .grid1 {
        grid-template-columns: repeat(7, 1fr);
      }
      .grid2 {
        grid-template-columns: repeat(5, 1fr);
      }
      .grid3 {
        grid-template-columns: repeat(3, 1fr);
      }
      .gridComponent {
        border: 1.5px solid #888;
        display: grid;
        background-color: #fff;
        border-radius: 4px;
        height: 30rem;
        overflow-y: scroll;
        overflow-x: scroll;

        .gridHead {
          border-right: 1.5px solid #888;
          text-align: center;
          padding: 1rem;
          @include font_size(b06);
          font-weight: 600;
          border-bottom: 1.5px solid #888;
          position: sticky;
        }
        .gridHead:last-child {
          border-right: none;
        }
        .gridbody {
          border-right: 1.5px solid #888;
          border-bottom: 1.5px solid #888;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem;
          @include font_size(b04);
          font-weight: 500;
          .johnBtn {
            gap: 6px;
            font-weight: 500;
            color: #fff;
            border-radius: 2px;
            background: #7a5dda;
            @include font_size(b06);
            padding: 0.8rem 1.5rem;
            border: none;
            letter-spacing: 1px;
          }
        }
      }
      .RpmReading {
        display: flex;
        gap: 1rem;

        .readingBox {
          display: flex;
          padding: 16.5px 8.5px 15.5px 9.5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 8px;
          border: 0.5px solid #888;
          box-shadow: 0px 2px 6px 2px #dfd6d6;
        }
        .main {
          display: flex;
          padding: 25.5px 25.5px 15.5px 28.5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 8px;
          border: 0.5px solid #888;
          box-shadow: 0px 2px 6px 2px #dfd6d6;
        }
        .redBox {
          background: linear-gradient(
            224deg,
            rgba(231, 54, 96, 0.87) 0%,
            #f92543 100%
          );
        }
        .blueBox {
          background: linear-gradient(
            225deg,
            #7a5dda 0%,
            rgba(5, 17, 31, 0.79) 100%
          );
        }
        .readingBox:nth-child(1) {
          background: var(
            --grey-linear,
            linear-gradient(180deg, #fff 0%, rgba(153, 150, 175, 0.1) 100%)
          );
          //   background-color: #7a5dda;
        }
        .readingBox:nth-child(2) {
          background: linear-gradient(
            at center,
            #fff 0%,
            rgba(255, 199, 107, 0.1) 100%
          );
        }
        .readingBox:nth-child(3) {
          background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(22, 51, 205, 0.05) 100%
          );
        }
        .readingBox:nth-child(4) {
          background: linear-gradient(
            at center,
            #fff 0%,
            rgba(82, 231, 156, 0.1) 100%
          );
        }
        .readingBox:nth-child(5) {
          background: linear-gradient(
            at top,
            #fff 0%,
            rgba(238, 127, 58, 0.1) 100%
          );
        }
        .readingBox:nth-child(6) {
          background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(129, 242, 16, 0.05) 100%
          );
        }
        .readingBox:nth-child(7) {
          background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(227, 18, 156, 0.1) 100%
          );
        }
      }
    }
  }
  .section2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 1rem;
  }
}
.num {
  @include font_size(h05);
  color: #505050;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}
.txt {
  @include font_size(b03);
  color: #505050;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
}
.txt2 {
  @include font_size(b05);
  color: #505050;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
}
.txt-lite {
  @include font_size(b03);

  color: #505050;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
}
.Box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.main-Box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .main-num {
    color: #fff;
  }
  .main-txt {
    color: #fff;
  }
}
