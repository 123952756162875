@import "../../../sass/generalUtility";
@import "../../../sass/colorPalette";
@import "../../../sass/fontSizeUtility";

.notesSub {
  height: 100%;
  overflow: hidden;
  .headerSection {
    margin: 1rem;
    > .title {
      @include font_size(b01);
      text-transform: capitalize;
      text-align: center;
      font-weight: 500;
    }
    .headerButtonSection {
      display: flex;
      justify-content: end;
      > button {
        @include font_size(h01);
        cursor: pointer;
        outline: none;
        border: none;
        > .icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .bodySection {
    height: 80%;
    overflow-y: scroll;
    .notesContainer {
      background-color: #fff;
      margin-bottom: 1rem;
      margin-top: 1rem;
      border-radius: 4px;
      border: 1px solid #333;

      > .title {
        > .title_icon {
          @include font_size(b03);
          display: flex;
          align-items: center;
        }
        border-bottom: 1px solid #333;
      }
      > .questionariebody {
        padding: 5px;
      }
    }

    .questionarie {
      margin-bottom: 0.5rem;
      padding: 5px;
      &:nth-child(even) {
        background-color: #eee;
      }
      .buttonsCont {
        width: 100%;
        display: flex;
        @include font_size(h01);
        cursor: pointer;
        gap: 1rem;
        justify-content: end;
      }
      > p {
        @include font_size(b03);
        font-weight: 500;
        padding: 5px;
      }
      .inputComponent {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        @include font_size(b03);
        margin-bottom: 1rem;

        > span {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding-left: 20px;
        }
      }

      .textAreaComponent {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-bottom: 1rem;
        > input {
          width: 100%;
          height: 6rem;
          padding: 0 0.5rem;
        }
      }

      .responceHistory {
        margin-bottom: 1rem;
      }
      .hidden {
        display: none;
      }
    }
  }
}
