@import "../sass/colorPalette";
@import "../sass/buttonModules";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/utility.scss";
.ViewTable {
  .advanceSearchBox {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
    .box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      //   align-items: baseline;
      .head {
        @include font_size(b03);
      }
      > input {
        width: 100%;
        height: 2.5rem;
        padding: 0.7rem;
        border: 1px solid #000;
      }

      .checkbox {
        > input {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
      .select2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // flex-direction: column;
        gap: 1rem;
        > span {
          > input {
            width: 100%;
            height: 2.5rem;
            padding: 0.7rem;
            border: 1px solid #000;
          }
        }
      }
    }
  }
  .prevNext {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    > p {
      @include font_size(b05);
      white-space: nowrap;
    }
    .prevBtn {
      // text-align: end;
      width: 100%;
      display: flex;
      justify-content: end;
      //   justify-content: space-between;
      //   gap: 1rem;
      > button {
        @include font_size(b04);
        outline: none;
        padding: 5px 8px;
        border: none;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        // margin-bottom: 1rem;
        &:first-child {
          border-left: 1px solid #000;
        }
      }
    }
  }

  .gridBody {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    border: 1px solid #888;
    border-radius: 4px;
    height: 200px;
    padding-bottom: 10rem;
    overflow: scroll;
  }

  .gridHeader {
    display: contents;
  }

  .gridHead {
    position: sticky;
    top: 0;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
    @include font_size(b04);
    padding: 0.5rem;
    color: #222;
    font-weight: 500;
    z-index: 1;
  }

  .gridContent {
    display: contents;
  }

  .gridBox {
    display: flex;
    justify-content: center;
    @include font_size(b04);
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
    align-items: center;
    font-weight: 400;
    padding: 1rem;
    color: #222;
  }
}
