@import "../sass/colorPalette";
@import "../sass/fontSizeUtility";
@import "../sass/generalUtility";
@import "../sass/buttonModules";
.percentage {
  width: 100%;
  height: 100%;
  position: relative;
}

.outer {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.15),
    -3px -3px 5px -1px rgba(255, 255, 255, 0.7);
}

.inner {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: inset 2px 2px 3px -1px rgba(0, 0, 0, 0.2),
    inset -2px -2px 3px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 6px 5px -5px rgba(0, 0, 0, 0.05);
}

#number {
  font-weight: 600;
  @include font_size(b04);

  color: #555;
}

.circle {
  fill: none;
  animation: anim 2s linear forwards;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
}
