@import "../sass/fontSizeUtility";

.EligiblePatientsFilter {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  margin: 3rem 0;
  .box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    //   align-items: baseline;
    .head {
      @include font_size(b03);
      white-space: nowrap;
    }
    > input {
      width: 100%;
      height: 2.5rem;
      // padding: 0.5rem;
      border: 1px solid #000;
    }
  }
}
