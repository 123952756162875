.patientsInsuranceContainer {
  .patientsBasicInfoContainer4 {
    grid-column-gap: 1rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, calc(3rem + 2.5vw));
    grid-column-gap: 1rem;
  }
}
