@import "../../sass/generalUtility";

.viewPopUpCont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .viewPopContent {
    width: 60vw;
    position: relative;
    top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    // margin-top: calc($topBarHeight);
    margin-bottom: 2rem;
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    z-index: 9999;

    .closeIcon {
      position: absolute;
      cursor: pointer;
      top: 1%;
      right: 1%;
      &:hover {
        color: #555;
      }
    }
    .popchild {
      padding-top: 1rem;
    }
  }
}
