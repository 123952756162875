@import "../../sass/generalUtility";
@import "../../sass/colorPalette";
@import "../../sass/fontSizeUtility";

.carePlanBodySection {
  width: 100%;
  height: 80vh;
  margin: 1.5rem 0 2rem;
  display: flex;
  gap: 1rem;
  .content-section {
    padding: 2rem;
    width: 100%;
    background-color: #eee;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border-radius: 4px;
    height: 90%;
    overflow-y: scroll;

    .date-section {
      width: 100%;
      display: flex;
      gap: 6rem;
      .sub {
        @include font_size(b05);
        margin-bottom: 0.4rem;
        color: #888888;
      }
      .date {
        @include font_size(b01);
      }
    }

    .dropDown {
      width: 100%;
      border-radius: 4px;
      .dropComponents {
        width: 100%;
        padding-bottom: 1rem;
        display: flex;
        gap: 2rem;
      }
    }
  }
}
