@import "../sass/fontSizeUtility";
@import "../sass/widgetUtility";

.alotmentTab {
  width: 100%;

  margin-top: 1rem;
  margin-bottom: 1rem;
  border-style: solid;
  border-color: #6d6d71;
  border-width: 0.5px;
  border-radius: 5px;

  padding-bottom: 1rem;
  .alotmentContainer {
    display: flex;
    flex-direction: column;

    .element-container {
      background-color: #eeeeee;

      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      justify-content: space-between;
      gap: 1rem;
      .element {
        display: flex;
        padding: 8px;
        gap: 1rem;
        .TabTitle-head {
          @include widgets-title;
          font-weight: 500;
          color: #000;
          margin-bottom: 0;
          white-space: nowrap;
        }
      }

      .TabTitle {
        @include widgets-title;
        padding: 8px;
        font-weight: 500;
        color: #000;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
    .overflow-container {
      height: 30vh;
      overflow-y: scroll;
    }
  }
}
