@import "../sass/fontSizeUtility";
@import "../sass/widgetUtility";

.TechnicianWidget {
  @include widgets-title;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  width: 100%;
  border-style: solid;
  border-color: #6d6d71;
  border-width: 0px 0px 0.5px 0px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  .element-cont {
    display: flex;
    padding: 8px;
    gap: 1rem;
    .tabelement {
      .effect-date {
        @include font_size(b05);
      }
    }
    .checkbox {
    }
  }
  .accessDisplay {
    padding: 8px 0;
  }
  .toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 8px;
    .toggle-cont {
      border: none;
      position: relative;
      aspect-ratio: 2/1;
      width: auto;
      height: 1.6rem;
      background-color: #888888;
      border-radius: 25px;
      transition: transform 0.2s ease;
      cursor: pointer;
      transition: all 350ms ease-in-out;
      .slider {
        position: absolute;
        top: 0%;
        left: 0%;
        aspect-ratio: 1/1;
        width: auto;
        height: 100%;
        background-color: white;
        border: 3px solid #888888;
        border-radius: 50%;
        transition: inherit;
      }
      &.active {
        background-color: #7a5dda;
        .slider {
          border: 2px solid #7a5dda;
          left: 50%;
        }
      }
    }
  }
}
